import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Lang from "../lang";
import { isMobile } from "../Utils/Utils";

const useStyles = makeStyles((theme) => ({
  buttonGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type ExpenseProps = {
  handleOnChange: (e: any, key: number) => void;
  keyValue: number;
  renderCopyButton: (data: any, key: any) => void;
  deleteExpense: (key: number) => void;
  data: any;
  renderCustomFields?: (data: any, key: number) => void;
};

export default function Expense(props: ExpenseProps) {
  const lang = Lang.getInstance();
  const classes = useStyles();
  const {
    handleOnChange,
    keyValue,
    renderCopyButton,
    deleteExpense,
    data,
    renderCustomFields,
  } = props;
  return (
    <Grid container spacing={3} key={keyValue}>
      <Grid
        item
        xs={12}
        md={renderCustomFields ? 4 : 5}
        lg={renderCustomFields ? 4 : 5}
        xl={renderCustomFields ? 4 : 5}
      >
        <TextField
          label={lang.get("expense")}
          name="expense"
          variant="outlined"
          value={data.expense}
          fullWidth
          required
          onChange={(e) => handleOnChange(e, keyValue)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={renderCustomFields ? 3 : 5}
        lg={renderCustomFields ? 3 : 5}
        xl={renderCustomFields ? 3 : 5}
      >
        <TextField
          label={lang.get("value")}
          name="value"
          variant="outlined"
          type="number"
          required
          value={data.value}
          fullWidth
          onChange={(e) => handleOnChange(e, keyValue)}
        />
      </Grid>
      {renderCustomFields && renderCustomFields(data, keyValue)}
      <Grid item xs={6} md={1} lg={1} xl={1} className={classes.buttonGrid}>
        {renderCopyButton(data, keyValue)}
      </Grid>

      <Grid item xs={6} md={1} lg={1} xl={1} className={classes.buttonGrid}>
        <IconButton>
          <Tooltip
            title={lang.get("delete")}
            onClick={() => deleteExpense(keyValue)}
          >
            <Delete fontSize="default" color={"error"} />
          </Tooltip>
        </IconButton>
      </Grid>
      {isMobile() && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
}
