import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  makeStyles,
  Paper,
  Select,
  Tooltip,
} from "@material-ui/core";
import Lang from "../lang";
import { ControlPoint } from "@material-ui/icons";
import { get, post, UrlEnum } from "../Utils/Utils";
import { useEffect, useState } from "react";
import Expense from "./Expense";
import { ContentCopy } from "@mui/icons-material";
import CachedDataSingleton from "../cachedDataSingleton";
import Config from "../Utils/Config";

const useStyles = makeStyles((theme) => ({
  paperCell: {
    marginBottom: 25,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "10px",
  },
  totalsGrid: {
    margin: "12px 10px 5px",
  },
  list: { padding: 15 },
  saveButton: {
    color: "#fff",
    marginTop: "10px",
  },
}));

type EstimatedCostsProps = {
  projectId: number;
  handleLoading: (loading: boolean) => void;
};

export default function EstimatedCosts(props: EstimatedCostsProps) {
  const { projectId, handleLoading } = props;
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const vatRates = cachedData.get("vatTypes");
  const classes = useStyles();
  const [estimatedBudget, setEstimatedBudget] = useState({
    id: null,
    data: [],
  });
  const [costs, setCosts] = useState({
    totalWithTVA: 0,
    totalWithoutTVA: 0,
    TVA: 0,
  });

  /**
   *
   */
  useEffect(() => {
    let costsTotalWithTVA = 0;
    let costsTotalWithoutTVA = 0;
    let costsTVA = 0;
    estimatedBudget.data?.map(
      (cost: { expense: string; value: any; vatRate: any }) => {
        costsTotalWithTVA += parseFloat(cost.value || 0);
        const costNoTva =
          parseFloat(cost.value || 0) / (1 + parseFloat(cost.vatRate) / 100);
        costsTotalWithoutTVA += costNoTva;
        costsTVA += (parseFloat(cost.vatRate) / 100) * costNoTva;
      }
    );
    setCosts({
      totalWithTVA: costsTotalWithTVA,
      totalWithoutTVA: costsTotalWithoutTVA,
      TVA: costsTVA,
    });
  }, [estimatedBudget]);

  /**
   *
   */
  useEffect(() => {
    if (projectId)
      //get budget estimate years
      get(`${UrlEnum.budgetEstimates}/project/${projectId}`).then(
        (response) => {
          if (!response.error && response?.estimate_data) {
            let newExpenses = JSON.parse(response?.estimate_data);
            setEstimatedBudget({
              id: response.id,
              data: newExpenses,
            });
          }
        }
      );
  }, []);

  /**
   *
   * @param data
   * @param key
   */
  const copyExpense = (data: { expense: string; value: number }) => {
    let newExpenses: any = estimatedBudget ? [...estimatedBudget.data] : [];
    newExpenses.push(data);
    setEstimatedBudget({
      ...estimatedBudget,
      data: newExpenses,
    });
  };

  /**
   *
   * @param event
   */
  const handleOnChange = (event: any, key: any) => {
    const newExpenses: any = [...estimatedBudget.data]; // Shallow copy of the array
    newExpenses[key] = {
      ...newExpenses[key],
      [event.target.name]: event.target.value,
    }; // Deep copy of the object being updated
    setEstimatedBudget({
      ...estimatedBudget,
      data: newExpenses,
    });
  };

  /**
   *
   * @param key
   */
  const deleteExpense = (key: any) => {
    let newExpenses = estimatedBudget ? [...estimatedBudget.data] : [];
    newExpenses.splice(key, 1);
    setEstimatedBudget({
      ...estimatedBudget,
      data: newExpenses,
    });
  };

  /**
   *
   */
  const addNewExpense = () => {
    //create copy of array estimatedBudget
    let newExpenses: any = estimatedBudget ? [...estimatedBudget.data] : [];
    newExpenses.push({
      expense: "",
      value: 0,
      vatRate: 0,
    });
    setEstimatedBudget({
      ...estimatedBudget,
      data: newExpenses,
    });
  };

  const handleSaveProjectEstimatedCosts = () => {
    handleLoading(true);
    let url = `${UrlEnum.budgetEstimates}${
      estimatedBudget.id ? "/" + estimatedBudget.id : ""
    }`;
    post(url, {
      id: estimatedBudget.id,
      estimate_data: estimatedBudget.data,
      projectId: projectId,
    }).then((response) => {
      handleLoading(false);
      if (!response.error && response.estimate_data) {
        let newExpenses = JSON.parse(response.estimate_data);
        setEstimatedBudget({
          id: response.id,
          data: newExpenses,
        });
      }
    });
  };

  /**
   *
   * @param data
   * @param key
   * @returns
   */
  const renderTVA = (
    data: {
      expense: string;
      value: number;
      vatRate: number;
    },
    key: number
  ) => {
    return (
      <Grid item xs={12} md={12} lg={3} xl={3}>
        <FormControl required={true} fullWidth>
          <InputLabel>{lang.get("vatRate")}</InputLabel>
          <Select
            native
            name="vatRate"
            id="vatRate"
            fullWidth
            value={data?.vatRate}
            onChange={(e) => handleOnChange(e, key)}
          >
            {vatRates?.map((vt: any, index: number) => (
              <option key={index} value={vt.value}>
                {vt.description}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  /**
   *
   * @param data
   * @param key
   * @returns
   */
  const renderCopyButton = (
    data: { expense: string; value: number },
    key: any
  ) => {
    return (
      <>
        <IconButton>
          <Tooltip title={lang.get("copy")} onClick={() => copyExpense(data)}>
            <ContentCopy />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  return (
    <Paper className={classes.paperCell}>
      <form
        id="estimated-costs-form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSaveProjectEstimatedCosts();
        }}
      >
        <div className={classes.headerContainer}>
          <h3>
            {lang.get("estimatedCosts")}
            <IconButton
              color="inherit"
              title={lang.get("estimatedCosts")}
              aria-label={lang.get("estimatedCosts")}
              id="addNew"
              onClick={addNewExpense}
            >
              <ControlPoint />
            </IconButton>
          </h3>
          <Button
            variant="contained"
            color="primary"
            id="save"
            className={classes.saveButton}
            type="submit"
            form="estimated-costs-form"
          >
            {lang.get("save")}
          </Button>
        </div>

        <Grid container className={classes.totalsGrid}>
          <Grid item md={4}>
            <strong>{lang.get("totalWithVat")}: </strong>
            {costs.totalWithTVA.toFixed(Config.noOfDecimals)}
          </Grid>
          <Grid item md={4}>
            <strong>{lang.get("totalNoVat")}: </strong>
            {costs.totalWithoutTVA.toFixed(Config.noOfDecimals)}
          </Grid>
          <Grid item md={4}>
            <strong>{lang.get("vat")}: </strong>
            {costs.TVA.toFixed(Config.noOfDecimals)}
          </Grid>
        </Grid>
        <Divider />
        <List className={classes.list}>
          {estimatedBudget?.data?.length
            ? estimatedBudget?.data?.map(
                (
                  data: {
                    expense: string;
                    value: number;
                    vatRate: number;
                  },
                  key: number
                ) => {
                  return (
                    <Expense
                      renderCustomFields={renderTVA}
                      handleOnChange={handleOnChange}
                      keyValue={key}
                      renderCopyButton={renderCopyButton}
                      deleteExpense={deleteExpense}
                      data={data}
                    />
                  );
                }
              )
            : null}
        </List>
      </form>
    </Paper>
  );
}
