import React, { PureComponent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
  Select,
  Paper,
  FormControl,
  InputLabel,
  Grid,
  createStyles,
  Theme,
  Checkbox,
  Fab,
  MenuItem, IconButton
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Lang from "../lang";
import CachedDataSingleton from "../cachedDataSingleton";
import {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import { Address, Client, Project } from "../Models/Models";
import withWidth from "@material-ui/core/withWidth";
import EditClientModal from "./EditClientModal";
import CloseIcon from "@material-ui/icons/Close";
import theme from "../Theme/Theme";

/**
 * module available lang
 */
const lang = Lang.getInstance();

/**
 * props type
 */
type ProjectGeneralInfoProps = {
  project: Project;
  open: boolean;
  onChange: (e: any, value: any) => void;
  onClientChange: (
    e: any,
    value: Client | null,
    reason: AutocompleteChangeReason,
    details?: any
  ) => void;
  classes: any;
  onSave: () => void
};

/**
 * state type
 */
type ProjectGeneralInfoState = {
  editModal: boolean;
  sameAsClient: boolean;
};

/**
 * class definition
 */
class ProjectGeneralInfo extends PureComponent<
  ProjectGeneralInfoProps,
  ProjectGeneralInfoState
  > {

  // class properties
  cachedData: any;
  clients: any;
  countries: Array<Object>;

  /**
   * constructor
   * @param props ProjectGeneralInfoProps
   */
  constructor(props: ProjectGeneralInfoProps) {
    super(props);

    this.cachedData = CachedDataSingleton.getInstance();
    this.clients = this.cachedData.get("clients");
    this.countries = this.cachedData.get("countries");

    this.state = {
      editModal: this.props.open,
      sameAsClient: true,
    };
  }
  verifyAddresses(){
    if(Address.stringify(this.props.project.client?.address) === Address.stringify(this.props.project.site_address))
      return true;
    else return false;
  }
  componentDidMount(){
      this.setState({sameAsClient: this.verifyAddresses()});
  }
  /**
   * Render method
   */
  render() {
    const classes = this.props.classes;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={3} sm={3} md={3}>
              <p>
                <label>{lang.get("number")}:</label>{" "}
                {this.props.project.folderNo}
              </p>
              <p>
                <label>{lang.get("title")}:</label> {this.props.project.title}
              </p>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <p>
                <label>{lang.get("client")}:</label>{" "}
                {this.props.project.client?.name}
              </p>
              <p>
                <label>{lang.get("vatNo")}:</label>{" "}
                {this.props.project.client?.vatNo && this.props.project.client?.vatNo !== 'null' ? this.props.project.client?.vatNo:""}
              </p>
              <p>
                <label>{lang.get("clientAddress")}:</label>{" "}
                {Address.stringify(this.props.project.client?.address)}
              </p>
              <p>
                <label>{lang.get("mobile")}:</label>{" "}
                <a className={classes.link} href={'tel:' + this.props.project.client?.mobile}>{this.props.project.client?.mobile}</a>
              </p>
              <p>
                <label>{lang.get("email")}:</label>{" "}
                {this.props.project.client?.email}
              </p>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <p>
                <label>{lang.get("siteAddress")}</label>
              </p>
              <p>{Address.stringify(this.props.project.site_address)}</p>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Fab
                onClick={() => this.setState({ editModal: true })}
                style={{backgroundColor: theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}
                className={classes.editBtn}
                aria-label="edit"
              >
                <EditIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>

        {/** EDIT modal */}
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          className={classes.modalWindow}
          open={this.state.editModal}
          onClose={() => this.setState({ editModal: false })}
          classes={{ scrollPaper: classes.modalScrollPaper }}
        >
          <DialogTitle id="dialog-title">
            {lang.get("edit")} {lang.get("folder")}
            <IconButton
              style={{ position: "absolute", right: 0, top: 0 }}
              color="inherit"
              id="addNew"
              onClick={() => this.setState({ editModal: false })}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ValidatorForm instantValidate={false}
              onSubmit={() => {
                this.props.onSave();
                this.setState({ editModal: false });
              }}>
              <Grid spacing={3} container>
                <Grid item md={6} sm={12} xs={12}>
                  <FormControl required={true} fullWidth={true}>
                    <TextValidator
                      disabled={this.props.project.status.id !== 1}
                      fullWidth
                      required={true}
                      id="folderNo"
                      name="folderNo"
                      label={lang.get("number")}
                      onChange={this.props.onChange}
                      validators={["required"]}
                      value={this.props.project.folderNo}
                      errorMessages={lang.get("fieldRequired")}
                    />
                  </FormControl>
                  <TextValidator
                    disabled={this.props.project.status.id !== 1}
                    fullWidth
                    required={true}
                    id="title"
                    name="title"
                    label={lang.get("title")}
                    onChange={this.props.onChange}
                    value={this.props.project.title || ""}
                    validators={["required"]}
                    errorMessages={lang.get("fieldRequired")}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <EditClientModal
                    client={this.props.project.client}
                    onClientChange={(e: any, client: Client | null, reason: AutocompleteChangeReason, details?: any) => this.props.onClientChange(e, client, reason, this.state.sameAsClient ? "sameAsClient" : null)}
                    disabled={false}
                  />
                  <div>
                    {lang.get("siteAddress")}
                    <Checkbox
                      checked={this.state.sameAsClient}
                      style={{color:theme.palette.header?.main }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                        const checked = e.target.checked;
                        this.setState({ sameAsClient: checked });
                        if(checked && this.props.project.client?.address.address !== this.props.project.site_address.address){
                          this.props.onClientChange(e, this.props.project.client, 'select-option', "sameAsClient");
                        }
                      }}
                    />
                    <em>{lang.get("sameAsClient")}</em>
                  </div>
                </Grid>
              </Grid>

              <br />
              <h4 style={{ marginLeft: 0 }}>{lang.get("siteAddress")}</h4>
              <Grid container spacing={3}>
                <Grid item md={6} sm={12} xs={12}>
                  <TextValidator
                    disabled={this.props.project.status.id !== 1}
                    label={lang.get("address")}
                    value={this.props.project.site_address.address || ""}
                    name="site_address.address"
                    id="site_address.address"
                    fullWidth
                    onChange={(event: any, model: any) => {
                      this.setState({ sameAsClient: false });
                      this.props.onChange(event, model);
                    }}
                  />
                  <TextValidator
                    disabled={this.props.project.status.id !== 1}
                    label={lang.get("code")}
                    value={this.props.project.site_address.code || ""}
                    name="site_address.code"
                    id="site_address.code"
                    fullWidth
                    onChange={this.props.onChange}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <TextValidator
                    disabled={this.props.project.status.id !== 1}
                    label={lang.get("city")}
                    value={this.props.project.site_address.city || ""}
                    name="site_address.city"
                    id="site_address.city"
                    fullWidth
                    onChange={this.props.onChange}
                  />

                  {/** country  */}
                  <FormControl fullWidth>
                    <InputLabel>{lang.get("country")}</InputLabel>
                    <Select
                      fullWidth
                      disabled={this.props.project.status.id !== 1}
                      id="site_address.country"
                      value={this.props.project.site_address.fk_countryId}
                      onChange={(e) => this.props.onChange(e, null)}


                    >
                      {this.countries.map((country: any) => (
                        <MenuItem
                          value={country.id}
                          id="site_address.fk_countryId"
                          key={country.id}

                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  id="save"
                  style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}
                >
                  {lang.get("save")}
                </Button>
              </DialogActions>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const styles = createStyles((theme: Theme) => ({
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "5px 15px 5px 15px",
    "& label": {
      fontWeight: "bold",
    },
  },
  modalScrollPaper: {
    width: "100%",
  },
  editBtn: {
    float: "right",
    marginTop: 12,
  },
  link: {
    color: theme.palette.info.dark
  }
}));

export default withWidth()(
  withStyles(styles, { withTheme: true })(ProjectGeneralInfo)
);
