import React, { Fragment } from "react";
import Lang from "../lang";
import {
  Theme,
  createStyles,
  withStyles,
  Grid,
  withWidth,
  AppBar,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Divider,
} from "@material-ui/core";
import {
  isMobile,
  UrlEnum,
  get,
  handleInputChange,
  post,
  DocTypes,
  Statuses,
  CostTypeEnum,
  StatusEnum,
  absoluteImagePaths,
  LocalUrlEnum,
} from "../Utils/Utils";
import {
  Client,
  Cost,
  Estimate,
  Invoice,
  Supplier,
  Address,
  Project as ProjectModel,
} from "../Models/Models";
import DocStatus from "../Components/DocStatus";
import DrawerLayout from "../Pages/DrawerLayout";
import qr_code from "../Images/icons/qr_code_2-black-18dp.svg";
// import PostAddIcon from "@material-ui/icons/PostAdd";
import ProjectGeneralInfo from "./ProjectGeneralInfo";
import { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import SwipeableViews from "react-swipeable-views";
import TabPanel from "../Components/TabPanel";
import InvoiceListComplete from "./InvoiceListComplete";
import EstimateListComplete from "./EstimateListComplete";
import FileExplorerMin from "../Components/FileExplorerMin";
import AddIcon from "@material-ui/icons/Add";
import AddProjectDoc from "./AddProjectDoc";
import { ResponsiveLine } from "@nivo/line";
import Config from "../Utils/Config";
import moment from "moment";
import TableComponent from "../Components/TableComponent";
import EditCosts from "./EditCosts";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CachedDataSingleton from "../cachedDataSingleton";
import PreviewPDF from "./PreviewPDF";
import ReactDOMServer from "react-dom/server";
import SimpleHeader from "../DocumentTemplates/SimpleHeader";
import RemoveIcon from "@material-ui/icons/Remove";
import VatAttestation from "./VatAttestation";
import CompanyContract from "./CompanyContract";
import ShowMore from "../Components/ShowMore";
import TimetrackingReportTable from "../Components/Timetracking/TimetrackingReportTable";
import theme from "../Theme/Theme";
import PVTemporarReception from "./PVTemporarReception";
import PVDefinitiveReception from "./PVDefinitiveReception";
import EstimatedCosts from "./EstimatedCosts";

const isMobileView = isMobile();
const lang = Lang.getInstance();

type ProjectProps = {
  match: any;
  history: any;
  setLoading: (value: boolean) => void;
  setOpenMessage: (value: string) => void;
  width: any;
  theme: Theme;
  classes: any;
  type: string;
  showSmallMessage: Function;
};

type ProjectState = {
  drawerOpen: boolean;
  model: ProjectModel;
  invoicesTabValue: number;
  orderTicketsTabValue: number;
  addDocuments: Array<Invoice> | Array<Estimate> | null;
  addDocType: string;
  editedCost: Cost | null;
  qrCode: string;
  reportData: any;
  timeTrackingUrl: string;
};

type Coordinates = {
  x: any;
  y: any;
};

const styles = (theme: Theme) =>
  createStyles({
    maxWidthFull: {
      maxWidth: "100%",
    },
    listHeader: {
      backgroundColor: "transparent",
      color: "#000",
    },
    extensionPanel: {
      boxShadow: "none",
      borderBottom: "1px solid #ccc",
    },
    invoiceTotals: {
      padding: 10,
      backgroundColor: "rgb(237, 208, 183)",
    },
    otTotals: {
      padding: 10,
      backgroundColor: "rgb(246, 151, 135)",
    },
    costsTotals: {
      padding: 10,
      backgroundColor: "rgb(244, 232, 132)",
    },
    laborCost: {
      padding: 10,
      backgroundColor: "rgb(200, 232, 132)",
    },
    profit: {
      padding: 10,
      backgroundColor: "rgb(94, 164, 255)",
      color: "#fff",
      borderRadius: 3,
    },
    paperCell: {
      marginBottom: 25,
    },
    unpaidBtn: {
      top: 0,
      backgroundColor: theme.palette.error.light,
      padding: 7,
    },
    monthlyBtn: {
      top: 0,
      backgroundColor: theme.palette.warning.light,
      padding: 7,
    },
    workforceLabel: {
      top: 0,
      backgroundColor: theme.palette.info.light,
      padding: 7,
    },
    purchaseLabel: {
      top: 0,
      backgroundColor: theme.palette.successColor?.main,
      padding: 7,
    },
    unpaid: {
      backgroundColor: theme.palette.error.light,
    },
    paid: {
      backgroundColor: theme.palette.success.light,
    },
    statusBtn: {
      width: "100%",
    },
  });

class Project extends React.PureComponent<ProjectProps, ProjectState> {
  matchParamsId: number;
  suppliers: Supplier[];
  cachedData: any;
  totalPrice = 0;
  timeTrackingColumns: Array<any> = [
    { label: lang.get("employee"), name: "subcontractorPersonName" },
    { label: lang.get("timeElapsed"), name: "elapsedTime" },
    { label: lang.get("withBreak"), name: "elapsedTimeWithPause" },
    { label: lang.get("laborCost"), name: "pricePerHours" },
  ];

  constructor(props: ProjectProps) {
    super(props);
    this.state = {
      drawerOpen: !isMobileView,
      model: new ProjectModel(),
      invoicesTabValue: 0,
      orderTicketsTabValue: 0,
      addDocuments: null,
      addDocType: "",
      editedCost: null,
      qrCode: "",
      reportData: [],
      timeTrackingUrl: "",
    };

    this.matchParamsId = this.props.match.params.id
      ? parseInt(this.props.match.params.id)
      : 0;
    this.cachedData = CachedDataSingleton.getInstance();
    this.suppliers = this.cachedData.get("suppliers");
  }

  componentDidMount() {
    if (
      this.matchParamsId &&
      this.matchParamsId > 0 &&
      this.state.model.id === 0
    ) {
      this.getProjectData();
    }
    if (this.matchParamsId === 0) {
      this.getProjectNumber();
    }
  }
  /**
   * get number from server
   */

  private async getProjectNumber() {
    const url = UrlEnum.projects + "/getNumber";
    const response = await get(url);
    if (response.errors) {
      this.props.setOpenMessage(
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors
      );
      return;
    }
    let p = { ...this.state.model };
    p.folderNo = response;
    this.setState({ model: p });
  }

  /**
   * get project data from server
   */
  private getProjectData() {
    this.props.setLoading(true);
    const url = UrlEnum.projects + "/" + this.matchParamsId;
    get(url)
      .then((data) => {
        if (!data || !data.id) {
          alert(lang.get("notFound"));
          this.props.setLoading(false);
          return;
        }
        this.props.setLoading(false);
        this.setState({ model: data }, () => {
          this.setState({
            timeTrackingUrl: `${UrlEnum.timeTracking}/report?startDate=${moment(
              this.state.model.created_at
            ).format(Config.momentUSDateFormat)}&endDate=${moment().format(
              Config.momentUSDateFormat
            )}&project=${this.state.model.id}&pause=30`,
          });
        });
      })
      .catch(() => {
        this.props.setLoading(false);
      });
  }

  /**
   * change status
   */
  async changeProjectState() {
    if (this.state.model.status !== null) {
      let nextState = this.state.model.status;
      if (this.state.model.status.id === Statuses.Active.id) {
        nextState = Statuses.Inactive;
      } else {
        nextState = Statuses.Active;
      }

      const url = `${UrlEnum.projects}/changeState/${this.state.model.id}/${nextState.id}`;
      const response = await get(url);
      if (response.errors) {
        alert(response.errors);
        return;
      }
      let newPrj = { ...this.state.model };
      newPrj.status = nextState;
      this.setState({ model: newPrj });
    }
  }

  /**
   *
   * @param e
   */
  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newPrj = handleInputChange(e, this.state.model);
    this.setState({ model: newPrj });
  }

  /**
   *
   * @param e
   * @param client
   * @param reason
   * @param details
   */
  onClientChange(
    e: any,
    client: Client | null,
    reason: AutocompleteChangeReason,
    details?: any
  ) {
    let newPrj = { ...this.state.model };
    newPrj.client = client;
    if (details === "sameAsClient" && client !== null) {
      newPrj.site_address = { ...client.address };
    }
    this.setState({ model: newPrj });
  }
  handleDelete(cost: Cost) {
    let newModel = Object.assign({}, this.state.model) as any;
    const index = newModel.costs.findIndex((c: any) => cost.id === c.id);
    if (index >= 0) {
      newModel.costs.splice(index, 1);
      this.setState({ model: newModel });
    }
  }

  /**
   *
   */
  async onSave() {
    this.props.setLoading(true);
    const url = UrlEnum.projects + "/" + this.state.model.id;
    const response = await post(url, this.state.model);
    if (response.errors) {
      const err =
        response.errors instanceof Array
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.showSmallMessage(err, StatusEnum.ERROR);
      this.props.setLoading(false);
      return;
    }
    const newPrj = { ...this.state.model };
    newPrj.id = response.id;
    this.setState({ model: newPrj });
    this.props.showSmallMessage(lang.get("success"));
    this.props.setLoading(false);

    this.props.history.push(LocalUrlEnum.projects + "/" + newPrj.id);
  }

  /**
   * get documents to attach according to type
   * @param docType
   */
  async addDoc(docType: string) {
    //get client invoices
    const response = await get(
      UrlEnum.projects +
        "/getClientDocuments/" +
        docType +
        "/" +
        this.state.model.client?.id
    );
    if (response.errors) return;
    this.setState({ addDocuments: response, addDocType: docType });
  }

  async removeDoc(docType: string) {
    let list: Array<any> | null = [];
    const newModel = { ...this.state.model };
    switch (docType) {
      case DocTypes.invoice:
      case DocTypes.proforma:
      case DocTypes.creditNote:
        list = this.state.model.invoices?.slice() || [];
        newModel.invoices = list;
        break;
      case DocTypes.estimate:
      case DocTypes.orderTicket:
      case DocTypes.subcontractorOrderTicket:
        list = this.state.model.estimates?.slice() || [];
        newModel.estimates = list;
        break;
    }
    const selected = list?.filter((item: any) => item.selected);
    const selectedIds = selected?.map((item: any) => item.id);
    const url = UrlEnum.projects + "/detachDoc/" + docType.toLowerCase();
    const response = await post(url, { ids: selectedIds });
    if (!response.errors) {
      if (list) {
        for (var i = list.length - 1; i >= 0; i--) {
          if (list[i].selected) {
            list.splice(i, 1);
          }
        }
      }

      this.setState({ model: newModel });
      this.props.showSmallMessage(lang.get("success"));
    } else if (selected.length === 0) {
      this.props.showSmallMessage(lang.get("selectError"), StatusEnum.ERROR);
    } else {
      this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
    }
  }

  /**
   * attach documents to project
   */
  async attachDoc() {
    if (!this.state.addDocuments || this.state.addDocuments.length === 0) {
      this.setState({ addDocuments: null });
      return;
    }

    const url = UrlEnum.projects + "/attachDoc";
    let docs = [];
    let type = "";
    for (let doc of this.state.addDocuments) {
      if (doc.selected) {
        type = doc.type;
        docs.push({
          id: doc.id,
          fk_FolderId: this.state.model.id,
          type: doc.type,
        });
      }
    }

    const response = await post(url, { docs: docs });
    if (response.errors) return;

    let project = { ...this.state.model };
    if (
      type === DocTypes.invoice ||
      type === DocTypes.proforma ||
      type === DocTypes.creditNote
    ) {
      if (project.invoices) {
        project.invoices = project.invoices.concat(response);
      }
    }
    if (
      type === DocTypes.orderTicket ||
      type === DocTypes.subcontractorOrderTicket
    ) {
      if (project.estimates) {
        project.estimates = project.estimates.concat(response);
      }
    }
    this.setState({ model: project, addDocuments: null });
  }

  /**
   *
   */
  public async addNewDoc() {
    if (!this.state.addDocType) return;
    let urlBase = "";
    let nextUrl = "";
    switch (this.state.addDocType) {
      case DocTypes.invoice:
        nextUrl = LocalUrlEnum.invoices;
        urlBase = UrlEnum.invoices;
        break;
      case DocTypes.proforma:
        nextUrl = LocalUrlEnum.proformas;
        urlBase = UrlEnum.invoices;
        break;
      case DocTypes.creditNote:
        nextUrl = LocalUrlEnum.creditNotes;
        urlBase = UrlEnum.invoices;
        break;
      case DocTypes.estimate:
        nextUrl = LocalUrlEnum.estimates;
        urlBase = UrlEnum.estimates;
        break;
      case DocTypes.orderTicket:
        nextUrl = LocalUrlEnum.orderTickets;
        urlBase = UrlEnum.estimates;
        break;
      case DocTypes.subcontractorOrderTicket:
        nextUrl = LocalUrlEnum.subcontractorordertickets;
        urlBase = UrlEnum.estimates;
        break;
    }
    if (!urlBase) return;

    const url = `${urlBase}/getNew/${this.state.addDocType}/${this.state.model.id}`;
    const response = await post(url, {});
    if (response.errors) {
      this.props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      return;
    }
    this.props.history.push(nextUrl + "/" + response.id);
  }

  /**
   * change paid cost status
   * @param cost
   */
  async setPaidCost(cost: Cost) {
    const nextState = cost.paid > 0 ? false : true;
    const response = await post(
      UrlEnum.folderCosts + "/setPaid/" + cost.id + "/" + nextState,
      {}
    );
    if (response.errors) return;
    cost.paid = nextState ? 1 : 0;
    let newModel = { ...this.state.model }; //create duplicate
    // newModel.costs = newModel.costs ? newModel.costs.slice() : []; // create duplicate
    if (newModel.costs) {
      let editedCost = newModel.costs.find((el) => el.id === cost.id);
      if (editedCost) {
        editedCost.paid = cost.paid;
        this.setState({ model: newModel });
      }
    }
  }
  /**
   *
   */
  async getQrCode() {
    const response = await get(
      UrlEnum.projects + "/getQrCode/" + this.state.model.id
    );
    if (response.errors) {
      this.props.showSmallMessage(lang.get("error"));
      return;
    }
    this.setState({ qrCode: response });
  }

  /**
   *
   */
  generateQrCodeHtml() {
    let content = ReactDOMServer.renderToString(
      <>
        <head>
          <meta charSet="UTF-8" />
        </head>
        <body>
          <SimpleHeader company={this.cachedData.get("company")} />
          <br />
          <h2 style={{ textAlign: "center" }}>
            {this.state.model.client?.name}
          </h2>
          <div style={{ border: "1px solid #ccc" }}>
            <h3 style={{ textAlign: "center" }}>{lang.get("siteAddress")}</h3>
            <h4 style={{ textAlign: "center" }}>
              {Address.stringify(this.state.model.site_address)}
            </h4>
          </div>
          <table style={{ margin: "0 auto", width: "100%", marginTop: "30px" }}>
            <tr>
              <th style={{ padding: "20px" }}>
                <img
                  src={this.state.qrCode}
                  style={{ width: "300px" }}
                  alt="qrCode"
                />
              </th>
              <th style={{ textAlign: "left", padding: "20px" }}>
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    width: "400px",
                    height: "217px",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "#ccc",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      margin: "0px",
                      padding: "10px",
                    }}
                  >
                    {lang.get("client")}
                  </p>
                  <p style={{ padding: "10px" }}>
                    {this.state.model.client?.name}
                    <br />
                    {this.state.model.client?.address.address}
                    <br />
                    {this.state.model.client?.address.code}
                    {this.state.model.client?.address.city}
                    <br />
                    {this.state.model.client?.mobile}
                    <br />
                    {this.state.model.client?.email}
                  </p>
                </div>
              </th>
            </tr>
          </table>
        </body>
      </>
    );
    let htmlContent: any = document.createElement("html");
    htmlContent.innerHTML = content;
    return absoluteImagePaths(htmlContent).innerHTML;
  }

  /**
   *
   */
  render() {
    // if we have params id and no model id, invoice is not fetched from the server
    if (this.matchParamsId > 0 && this.state.model.id === 0) return "";

    // const width = this.useWidth();
    const { classes } = this.props;
    const invoices: Invoice[] | undefined = [];
    const proformas: Invoice[] | undefined = [];
    const creditNotes: Invoice[] | undefined = [];

    const orderTickets: Estimate[] | undefined = [];
    const subcontractorOrderTickets: Estimate[] | undefined = [];

    let invoiceData: {
      id: string | number;
      color: string;
      data: Array<Coordinates>;
    } = {
      id: lang.get("invoices"),
      color: "HSL(272°, 70%, 50%)",
      data: [],
    };

    let otData: {
      id: string | number;
      color: string;
      data: Array<Coordinates>;
    } = {
      id: lang.get("orderTickets"),
      color: "rgba(0, 196, 36,1)",
      data: [],
    };

    let costsData: {
      id: string | number;
      color: string;
      data: Array<Coordinates>;
    } = {
      id: lang.get("costs"),
      color: "rgba(0, 196, 36,1)",
      data: [],
    };
    let costsTotalWithTVA = 0;
    let costsTotalWithoutTVA = 0;
    let costsTVA = 0;
    const cd = this.state.model.costs?.map((cost) => {
      costsTotalWithTVA += cost.amount;
      const costNoTva = cost.amount / (1 + cost.vatRate / 100);
      costsTotalWithoutTVA += costNoTva;
      costsTVA += (cost.vatRate / 100) * costNoTva;
      let x = moment(cost.docDate, Config.momentUSDateFormat);
      if (!x.isValid()) {
        x = moment(cost.created_at, Config.momentUSDateFormat);
      }
      return {
        x: x.format(Config.momentEUDateFormat),
        y: costsTotalWithTVA,
      };
    });
    costsData.data = cd ? cd : [];

    let invoicesTotals = {
      totalWithVat: 0,
      totalNoVat: 0,
      VAT: 0,
      paid: 0,
      toPay: 0,
    };
    let proformasTotals = 0;
    let creditNotesTotals = 0;

    let otTotals = {
      totalWithVat: 0,
      totalNoVat: 0,
      VAT: 0,
    };
    let sotTotals = 0;

    if (this.state.model.invoices) {
      for (const doc of this.state.model.invoices) {
        if (doc.type.toLowerCase() === DocTypes.invoice.toLowerCase()) {
          invoices.push(doc);
          const totalWTVA = (1 + doc.vatRate / 100) * doc.totalNoVat;
          invoicesTotals.totalWithVat +=
            totalWTVA - (doc.discount / 100) * totalWTVA;
          invoicesTotals.totalNoVat += doc.totalNoVat;
          invoicesTotals.totalNoVat -= (doc.discount / 100) * doc.totalNoVat;
          invoicesTotals.VAT += (doc.vatRate / 100) * doc.totalNoVat;
          invoicesTotals.paid += doc.totalPaid;
          invoicesTotals.toPay += doc.totalWithVat - doc.totalPaid;

          // chart data
          const coords: Coordinates = {
            x: moment(doc.invoiceDate, Config.momentUSDateFormat).format(
              Config.momentEUDateFormat
            ),
            y: invoicesTotals.totalWithVat,
          };
          invoiceData.data.push(coords);
        }
        if (doc.type.toLowerCase() === DocTypes.proforma.toLowerCase()) {
          proformas.push(doc);
          proformasTotals += doc.totalWithVat;
        }
        if (doc.type.toLowerCase() === DocTypes.creditNote.toLowerCase()) {
          creditNotes.push(doc);
          creditNotesTotals += doc.totalWithVat;
        }
      }
    }
    if (this.state.model.estimates) {
      for (const doc of this.state.model.estimates) {
        if (doc.type.toLowerCase() === DocTypes.orderTicket.toLowerCase()) {
          orderTickets.push(doc);
          const totalWTVA = (1 + doc.vatRate / 100) * doc.totalNoVat;
          otTotals.totalWithVat += totalWTVA - (doc.discount / 100) * totalWTVA;
          otTotals.totalNoVat += doc.totalNoVat;
          otTotals.totalNoVat -= (doc.discount / 100) * doc.totalNoVat;
          otTotals.VAT += (doc.vatRate / 100) * doc.totalNoVat;

          // chart data
          const coords: Coordinates = {
            x: moment(doc.startDate, Config.momentUSDateFormat).format(
              Config.momentEUDateFormat
            ),
            y: otTotals.totalWithVat,
          };
          otData.data.push(coords);
        }
        if (
          doc.type.toLowerCase() ===
          DocTypes.subcontractorOrderTicket.toLowerCase()
        ) {
          subcontractorOrderTickets.push(doc);
          sotTotals += doc.totalWithVat;
        }
      }
    }
    let chartData = [];
    if (invoiceData.data.length > 0) {
      chartData.push(invoiceData);
    }
    if (otData.data.length > 0) {
      chartData.push(otData);
    }
    if (costsData.data.length > 0) {
      chartData.push(costsData);
    }

    let costsTableColumns = [
      {
        label: lang.get("supplier"),
        name: "supplierName",
        customRender: (cost: Cost) => {
          const supplier = this.suppliers.find(
            (supplier: Supplier) => supplier.id === cost.fk_SupplierId
          );
          if (supplier) return supplier.name;
          return "";
        },
      },
      { label: lang.get("amount"), name: "amount" },
      { label: lang.get("description"), name: "description" },
      {
        label: lang.get("paid"),
        name: "paid",
        customRender: (cost: Cost) => {
          return (
            <div>
              <Button
                className={cost.paid < 1 ? classes.unpaid : classes.paid}
                onClick={() => this.setPaidCost(cost)}
              >
                {cost.paid < 1 ? lang.get("unpaid") : lang.get("paid")}
              </Button>
            </div>
          );
        },
      },
    ];

    let qrCodeHtml = "";
    if (this.state.qrCode) {
      qrCodeHtml = this.generateQrCodeHtml();
    }
    return (
      <DrawerLayout
        open={this.state.drawerOpen}
        drawerWidth={!isMobileView ? 300 : "100%"}
        handleDrawerClose={() =>
          this.setState({ drawerOpen: !this.state.drawerOpen })
        }
        title={lang.get("folder")}
        drawerChildren={
          <Fragment>
            <List>
              <ListItem button onClick={this.getQrCode.bind(this)}>
                <ListItemIcon>
                  <img src={qr_code} alt="qr" />
                </ListItemIcon>
                <ListItemText primary="QR" />
              </ListItem>

              <ListItem>
                <ListItemText primary={lang.get("generate")} />
              </ListItem>
              <CompanyContract
                company={this.cachedData.get("company")}
                client={this.state.model.client}
                orderTicket={orderTickets.length ? orderTickets[0] : null}
                setSmallMessage={this.props.showSmallMessage}
                project={this.state.model}
              />
              <PVTemporarReception
                company={this.cachedData.get("company")}
                client={this.state.model.client}
                setSmallMessage={this.props.showSmallMessage}
                project={this.state.model}
                leftToPay={invoicesTotals.toPay.toFixed(Config.noOfDecimals)}
              />
              <PVDefinitiveReception
                company={this.cachedData.get("company")}
                client={this.state.model.client}
                setSmallMessage={this.props.showSmallMessage}
                project={this.state.model}
                leftToPay={invoicesTotals.toPay.toFixed(Config.noOfDecimals)}
              />
              <VatAttestation
                company={this.cachedData.get("company")}
                client={this.state.model.client}
                setSmallMessage={this.props.showSmallMessage}
                docType={lang.get("project")}
                docData={{
                  series: "",
                  no: this.state.model.folderNo,
                  worksiteAddress: Address.stringify(
                    this.state.model.site_address
                  ),
                  date: moment(
                    this.state.model.created_at,
                    Config.momentUSDateFormatWithTime
                  ).format(Config.momentEUDateFormat),
                }}
              />

              <ListItem>
                <DocStatus
                  size="medium"
                  className={classes.statusBtn}
                  status={this.state.model.status}
                  onChangeStatus={this.changeProjectState.bind(this)}
                />
              </ListItem>
            </List>
          </Fragment>
        }
      >
        <Fragment>
          <ProjectGeneralInfo
            open={this.state.model.id === 0}
            project={this.state.model}
            onChange={this.onChange.bind(this)}
            onClientChange={this.onClientChange.bind(this)}
            onSave={this.onSave.bind(this)}
          />
          <br />
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              {/** CHART */}
              <Paper style={{ height: 300, marginBottom: 10 }}>
                <ResponsiveLine
                  data={chartData}
                  margin={{ top: 10, right: 110, bottom: 100, left: 70 }}
                  axisTop={null}
                  axisRight={null}
                  xScale={{
                    type: "time",
                    format: "%d-%m-%Y",
                    useUTC: false,
                    precision: "day",
                  }}
                  xFormat="time:%d-%m-%Y"
                  axisBottom={{
                    tickRotation: 90,
                    legend: lang.get("date"),
                    legendOffset: 90,
                    legendPosition: "middle",
                    tickSize: 5,
                    format: "%d-%m-%Y",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: lang.get("amount"),
                    legendOffset: -60,
                    legendPosition: "middle",
                  }}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 90,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              {/** SUMMARY */}
              <Paper style={{ padding: 20 }}>
                <h3>{lang.get("summary")}</h3>
                <div className={classes.invoiceTotals}>
                  <h3 style={{ margin: "0px" }}>{lang.get("invoices")}</h3>
                  <Grid container style={{ marginTop: 12, marginBottom: 5 }}>
                    <Grid item md={4}>
                      <strong>{lang.get("totalWithVat")}:</strong>{" "}
                      {invoicesTotals.totalWithVat.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("totalNoVat")}:</strong>{" "}
                      {invoicesTotals.totalNoVat.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("vat")}:</strong>{" "}
                      {invoicesTotals.VAT.toFixed(Config.noOfDecimals)}
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container style={{ marginTop: 12, marginBottom: 5 }}>
                    <Grid item md={4}>
                      <strong>{lang.get("paid")}:</strong>{" "}
                      {invoicesTotals.paid.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("leftToPay")}:</strong>
                      {invoicesTotals.toPay.toFixed(Config.noOfDecimals)}
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container style={{ marginTop: 12 }}>
                    <Grid item md={4}>
                      <strong style={{ fontStyle: "italic" }}>
                        {lang.get("totalToInvoice")}:{" "}
                      </strong>
                      <strong style={{ fontStyle: "italic" }}>
                        {" "}
                        {(
                          otTotals.totalWithVat - invoicesTotals.totalWithVat
                        ).toFixed(Config.noOfDecimals)}{" "}
                      </strong>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.otTotals}>
                  <h3 style={{ margin: "0px" }}>{lang.get("orderTickets")}</h3>
                  <Grid container style={{ marginTop: 12 }}>
                    <Grid item md={4}>
                      <strong>{lang.get("totalWithVat")}: </strong>
                      {otTotals.totalWithVat.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("totalNoVat")}: </strong>
                      {otTotals.totalNoVat.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("vat")}: </strong>
                      {otTotals.VAT.toFixed(Config.noOfDecimals)}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.costsTotals}>
                  <h3 style={{ margin: "0px" }}>{lang.get("costs")} </h3>
                  <Grid container style={{ marginTop: 12, marginBottom: 5 }}>
                    <Grid item md={4}>
                      <strong>{lang.get("totalWithVat")}: </strong>
                      {costsTotalWithTVA.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("totalNoVat")}: </strong>
                      {costsTotalWithoutTVA.toFixed(Config.noOfDecimals)}
                    </Grid>
                    <Grid item md={4}>
                      <strong>{lang.get("vat")}: </strong>
                      {costsTVA.toFixed(Config.noOfDecimals)}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.laborCost}>
                  <strong>{lang.get("laborCost")}: </strong>
                  {this.state.reportData?.totalPrice}
                </div>
                <br />
                <div className={classes.profit}>
                  <big>
                    <strong>
                      {lang.get("profit")}: &nbsp;
                      {(invoicesTotals.totalWithVat -
                      costsTotalWithTVA -
                      this.state.reportData?.totalPrice
                        ? invoicesTotals.totalWithVat -
                          costsTotalWithTVA -
                          this.state.reportData?.totalPrice
                        : 0
                      ).toFixed(Config.noOfDecimals)}
                    </strong>
                  </big>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              {/** INVOICES */}
              <Paper className={classes.paperCell}>
                <AppBar
                  position="relative"
                  style={{ backgroundColor: theme.palette.header?.main }}
                >
                  <Tabs
                    aria-label="invoiceTabs"
                    value={this.state.invoicesTabValue}
                    style={{ color: theme.palette.textColorSecondary?.main }}
                    onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
                      this.setState({ invoicesTabValue: newValue });
                    }}
                  >
                    <Tab
                      label={lang.get("invoices")}
                      aria-controls="invoices"
                      style={{ minWidth: "90px" }}
                    />
                    <Tab
                      label={lang.get("proformas")}
                      aria-controls="proofrmas"
                      style={{ minWidth: "90px" }}
                    />
                    <Tab
                      label={lang.get("creditNotes")}
                      aria-controls="creditNotes"
                      style={{ minWidth: "90px" }}
                    />
                  </Tabs>
                  {/* ------------------------------------------------------------ */}
                  <div style={{ position: "absolute", right: 0 }}>
                    <Tooltip title={lang.get("remove")} placement="bottom">
                      <IconButton
                        aria-describedby="remove"
                        onClick={() => {
                          let docType = DocTypes.invoice;
                          switch (this.state.invoicesTabValue) {
                            case 0:
                              docType = DocTypes.invoice;
                              break;
                            case 1:
                              docType = DocTypes.proforma;
                              break;
                            case 2:
                              docType = DocTypes.creditNote;
                              break;
                          }
                          this.removeDoc(docType);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                    {/* ------------------------------------------------------------ */}
                    <Tooltip title={lang.get("add")} placement="bottom">
                      <IconButton
                        aria-describedby="add"
                        onClick={() => {
                          let docType = DocTypes.invoice;
                          switch (this.state.invoicesTabValue) {
                            case 0:
                              docType = DocTypes.invoice;
                              break;
                            case 1:
                              docType = DocTypes.proforma;
                              break;
                            case 2:
                              docType = DocTypes.creditNote;
                              break;
                          }
                          this.addDoc(docType);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </AppBar>
                <SwipeableViews axis={"x"} index={this.state.invoicesTabValue}>
                  <TabPanel value={0} index={0}>
                    <InvoiceListComplete
                      isProject={true}
                      select={true}
                      invoices={invoices ? invoices : null}
                      showSmallMessage={this.props.showSmallMessage}
                      type={DocTypes.invoice}
                      key={invoices?.length}
                      classes={{
                        header: classes.listHeader,
                        extensionPanel: classes.extensionPanel,
                      }}
                    />
                    <br />
                    <Grid container>
                      <Grid item md={4}>
                        <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                        {invoicesTotals.totalWithVat.toFixed(
                          Config.noOfDecimals
                        )}
                      </Grid>
                      <Grid item md={4}>
                        <strong>{lang.get("paid")}</strong>: &nbsp;
                        {invoicesTotals.paid.toFixed(Config.noOfDecimals)}
                      </Grid>
                      <Grid item md={4}>
                        <strong>{lang.get("leftToPay")}</strong>: &nbsp;
                        {invoicesTotals.toPay.toFixed(Config.noOfDecimals)}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={1} index={1}>
                    <InvoiceListComplete
                      isProject={true}
                      select={true}
                      invoices={proformas ? proformas : null}
                      showSmallMessage={this.props.showSmallMessage}
                      key={proformas?.length}
                      type={DocTypes.proforma}
                      classes={{
                        header: classes.listHeader,
                        extensionPanel: classes.extensionPanel,
                      }}
                    />
                    <br />
                    <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                    {proformasTotals}
                  </TabPanel>
                  <TabPanel value={2} index={2}>
                    <InvoiceListComplete
                      isProject={true}
                      select={true}
                      invoices={creditNotes ? creditNotes : null}
                      showSmallMessage={this.props.showSmallMessage}
                      key={creditNotes?.length}
                      type={DocTypes.creditNote}
                      classes={{
                        header: classes.listHeader,
                        extensionPanel: classes.extensionPanel,
                      }}
                    />
                    <br />
                    <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                    {creditNotesTotals}
                  </TabPanel>
                </SwipeableViews>
              </Paper>

              {/** COSTS */}
              <Paper className={classes.paperCell}>
                {/* <p>&nbsp;</p> */}
                <h3>
                  {lang.get("costs")}
                  <IconButton
                    color="inherit"
                    title={lang.get("costs")}
                    aria-label={lang.get("costs")}
                    id="addNew"
                    onClick={() => {
                      let cost = new Cost();
                      cost.fk_FolderId = this.state.model.id;
                      cost.fk_ClientId = this.state.model.client
                        ? this.state.model.client.id
                        : 0;
                      this.setState({ editedCost: cost });
                    }}
                  >
                    <ControlPointIcon />
                  </IconButton>
                </h3>

                {this.state.model.costs?.length === 0 ? (
                  <TableComponent
                    url={UrlEnum.folderCosts}
                    models={this.state.model.costs}
                    key={this.state.model.costs.length}
                    editedModels={[this.state.editedCost]}
                    columns={costsTableColumns}
                    editCallback={(costModel: Cost) =>
                      this.setState({ editedCost: costModel })
                    }
                    deleteCallback={this.handleDelete.bind(this)}
                    search={false}
                    rowClassCallback={(cost: Cost) => {
                      let crtClasses = "";
                      if (
                        cost.paymentDeadline &&
                        moment(
                          cost.paymentDeadline,
                          Config.momentUSDateFormat
                        ).isBefore(moment()) &&
                        cost.paid < 1
                      ) {
                        crtClasses += classes.unpaid;
                      }
                      if (
                        (cost.isMonthly && cost.isMonthly > 0) ||
                        cost.monthlyCostId
                      ) {
                        crtClasses += ` ${classes.monthly}`;
                      }
                      if (cost.type === CostTypeEnum.PURCHASE) {
                        crtClasses += ` ${classes.purchase}`;
                      }
                      if (cost.type === CostTypeEnum.WORKFORCE) {
                        crtClasses += ` ${classes.workforce}`;
                      }
                      return crtClasses;
                    }}
                    customHeaderContent={
                      <Grid
                        container
                        style={{ marginTop: 12, marginBottom: 5 }}
                      >
                        <Grid item md={4}>
                          <strong>{lang.get("totalWithVat")}: </strong>
                          {costsTotalWithTVA.toFixed(Config.noOfDecimals)}
                        </Grid>
                        <Grid item md={4}>
                          <strong>{lang.get("totalNoVat")}: </strong>
                          {costsTotalWithoutTVA.toFixed(Config.noOfDecimals)}
                        </Grid>
                        <Grid item md={4}>
                          <strong>{lang.get("vat")}: </strong>
                          {costsTVA.toFixed(Config.noOfDecimals)}
                        </Grid>
                      </Grid>
                    }
                  />
                ) : (
                  <ShowMore>
                    <TableComponent
                      url={UrlEnum.folderCosts}
                      models={this.state.model.costs}
                      key={this.state.model.costs?.length}
                      editedModels={[this.state.editedCost]}
                      columns={costsTableColumns}
                      editCallback={(costModel: Cost) =>
                        this.setState({ editedCost: costModel })
                      }
                      deleteCallback={this.handleDelete.bind(this)}
                      search={false}
                      rowClassCallback={(cost: Cost) => {
                        let crtClasses = "";
                        if (
                          cost.paymentDeadline &&
                          moment(
                            cost.paymentDeadline,
                            Config.momentUSDateFormat
                          ).isBefore(moment()) &&
                          cost.paid < 1
                        ) {
                          crtClasses += classes.unpaid;
                        }
                        if (
                          (cost.isMonthly && cost.isMonthly > 0) ||
                          cost.monthlyCostId
                        ) {
                          crtClasses += ` ${classes.monthly}`;
                        }
                        if (cost.type === CostTypeEnum.PURCHASE) {
                          crtClasses += ` ${classes.purchase}`;
                        }
                        if (cost.type === CostTypeEnum.WORKFORCE) {
                          crtClasses += ` ${classes.workforce}`;
                        }
                        return crtClasses;
                      }}
                      customHeaderContent={
                        <Grid
                          container
                          style={{ marginTop: 12, marginBottom: 5 }}
                        >
                          <Grid item md={4}>
                            <strong>{lang.get("totalWithVat")}: </strong>
                            {costsTotalWithTVA.toFixed(Config.noOfDecimals)}
                          </Grid>
                          <Grid item md={4}>
                            <strong>{lang.get("totalNoVat")}: </strong>
                            {costsTotalWithoutTVA.toFixed(Config.noOfDecimals)}
                          </Grid>
                          <Grid item md={4}>
                            <strong>{lang.get("vat")}: </strong>
                            {costsTVA.toFixed(Config.noOfDecimals)}
                          </Grid>
                        </Grid>
                      }
                    />
                  </ShowMore>
                )}
                {this.state.editedCost ? (
                  <EditCosts
                    isCostInProject={true}
                    model={this.state.editedCost}
                    open={this.state.editedCost !== null}
                    onClose={() => {
                      this.setState({ editedCost: null });
                    }}
                    submitCallback={(model: Cost) =>
                      this.setState({ editedCost: model })
                    }
                    showSmallMessage={this.props.showSmallMessage}
                    hideClient={true}
                  />
                ) : null}
              </Paper>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              {/** Order tickets */}
              <Paper className={classes.paperCell}>
                <AppBar position="relative">
                  <Tabs
                    aria-label="orderTicketsTabs"
                    value={this.state.orderTicketsTabValue}
                    style={{
                      backgroundColor: theme.palette.header?.main,
                      color: theme.palette.textColorSecondary?.main,
                    }}
                    onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
                      this.setState({ orderTicketsTabValue: newValue });
                    }}
                  >
                    <Tab
                      label={lang.get("orderTickets")}
                      style={{ minWidth: "80px" }}
                    />
                    <Tab
                      classes={{ root: classes.maxWidthFull }}
                      label={lang.get("subcontractorOrderTickets")}
                      style={{ minWidth: "70px" }}
                    />
                  </Tabs>
                  <div style={{ position: "absolute", right: 0 }}>
                    <Tooltip title={lang.get("remove")} placement="bottom">
                      <IconButton
                        aria-describedby="remove"
                        onClick={() => {
                          let docType = DocTypes.orderTicket;
                          switch (this.state.orderTicketsTabValue) {
                            case 0:
                              docType = DocTypes.orderTicket;
                              break;
                            case 1:
                              docType = DocTypes.subcontractorOrderTicket;
                              break;
                          }
                          this.removeDoc(docType);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={lang.get("add")} placement="bottom">
                      <IconButton
                        aria-describedby="add"
                        onClick={() => {
                          let docType = DocTypes.orderTicket;
                          switch (this.state.orderTicketsTabValue) {
                            case 0:
                              docType = DocTypes.orderTicket;
                              break;
                            case 1:
                              docType = DocTypes.subcontractorOrderTicket;
                              break;
                          }
                          this.addDoc(docType);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </AppBar>
                <SwipeableViews
                  axis={"x"}
                  index={this.state.orderTicketsTabValue}
                >
                  <TabPanel value={0} index={0}>
                    <EstimateListComplete
                      isProject={true}
                      estimates={orderTickets ? orderTickets : null}
                      showSmallMessage={this.props.showSmallMessage}
                      type={DocTypes.orderTicket}
                      key={orderTickets?.length}
                      classes={{
                        header: classes.listHeader,
                        extensionPanel: classes.extensionPanel,
                      }}
                      select
                    />
                    <br />
                    <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                    {otTotals.totalWithVat.toFixed(Config.noOfDecimals)}
                  </TabPanel>
                  <TabPanel value={1} index={1}>
                    <EstimateListComplete
                      isProject={true}
                      estimates={
                        subcontractorOrderTickets
                          ? subcontractorOrderTickets
                          : null
                      }
                      showSmallMessage={this.props.showSmallMessage}
                      type={DocTypes.estimate}
                      key={subcontractorOrderTickets?.length}
                      classes={{
                        header: classes.listHeader,
                        extensionPanel: classes.extensionPanel,
                      }}
                      select
                    />
                    <br />
                    <strong>{lang.get("totalWithVat")}</strong>: &nbsp;
                    {sotTotals}
                  </TabPanel>
                </SwipeableViews>
              </Paper>
              {/** Estimated  costs */}
              <EstimatedCosts
                handleLoading={this.props.setLoading}
                projectId={this.state.model.id}
              />
              {/** File explorer */}
              <Paper>
                <FileExplorerMin
                  files={this.state.model.fileGallery}
                  url={UrlEnum.projects + "/gallery"}
                  id={this.state.model.id}
                  deleteUrl={UrlEnum.projects + "/fromGallery"}
                />
              </Paper>
              {/** Time tracking*/}
              <Paper>
                <div
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    paddingBottom: "15px",
                  }}
                >
                  <h3 style={{ margin: "10px 0px" }}>
                    {" "}
                    {lang.get("timekeeping")}{" "}
                  </h3>
                  {this.state.timeTrackingUrl ? (
                    this.state.reportData.items?.length === 0 ? (
                      <TimetrackingReportTable
                        url={this.state.timeTrackingUrl}
                        onFetchResult={(data: any) =>
                          this.setState({ reportData: data })
                        }
                        columns={this.timeTrackingColumns}
                      />
                    ) : (
                      <ShowMore>
                        <TimetrackingReportTable
                          url={this.state.timeTrackingUrl}
                          onFetchResult={(data: any) =>
                            this.setState({ reportData: data })
                          }
                          columns={this.timeTrackingColumns}
                        />
                      </ShowMore>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>

          {/** MODALS */}
          <AddProjectDoc
            open={this.state.addDocuments !== null}
            data={this.state.addDocuments}
            setChecked={(e: React.MouseEvent, el: any) => {
              el.selected = true;
            }}
            onClose={() => {
              this.setState({ addDocuments: null, addDocType: "" });
            }}
            onAdd={this.attachDoc.bind(this)}
            onAddNew={this.addNewDoc.bind(this)}
          />
        </Fragment>
        <PreviewPDF
          open={this.state.qrCode !== ""}
          onClose={() => this.setState({ qrCode: "" })}
          htmlContent={qrCodeHtml}
          footerHtml={""}
          setSmallMessage={this.props.showSmallMessage}
          emailData={{ to: "", subject: "", message: "" }}
          url={UrlEnum.pdf.replace("{type}", "qr").replace("{id}", "0")}
        />
      </DrawerLayout>
    );
  }
}

export default withWidth()(withStyles(styles, { withTheme: true })(Project));
