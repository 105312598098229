export default class Lang {
  static instance;
  constructor() {
    this.lang = {
      ro: {
        january: "Ianuarie",
        february: "Februarie",
        march: "Martie",
        april: "Aprilie",
        may: "Mai",
        june: "Iunie",
        july: "Iulie",
        august: "August",
        september: "Septembrie",
        october: "Octombrie",
        november: "Noiembrie",
        december: "Decembrie",
        home: "Acasă",
        invalidDate: "Dată invalidă",
        showMore: "Mai multe",
        showLess: "Mai puține",
        estimatedTotal: "Total estimat",
        actualTotal: "Total real",
        offer: "Ofertă",
        months: "Luni",
        navigation: "Navigare",
        noActivity:
          "Nu a fost detectată nicio activitate. Veți fi deconectat în scurt timp.",
        noEmployees: "Nu există angajați disponibili.",
        hr: "Resurse umane",
        additionalInformation: "Informații suplimentare",
        emailNotConfirmed: "Adresa de e-mail nu a fost încă confirmată.",
        pleaseConfirmEmail:
          "Un email de confirmare a fost trimis la adresa furnizata.",
        confirmEmail: "Vă rugăm să vă confirmați e-mailul pentru a continua.",
        confirmedEmail: "Email-ul dumneavoastră este confirmat.",
        resendEmail: "Retrimite e-mailul",
        receiveCC: "Adăugați în cc",
        noDisposableEmail: "Adresele e-mail de unică folosință sunt interzise.",
        vatValidationErr: "Validarea numărului de TVA a eșuat.",
        export: "Exportă",
        size: "Mărime",
        section: "Secțiune",
        container: "Container",
        embed: "Încorporare",
        ascending: "Ascendent",
        descending: "Descendent",
        sort: "Sortează",
        language: "ro",
        reference: "Referință",
        loading: "Se incarcă",
        warning: "Atenție",
        alreadySelected: "Deja selectat",
        requiredColumns: "Coloane obligatorii",
        noData: "Fără date",
        report: "Raport",
        userRoles: "Roluri utilizator",
        atLeast13years:
          "Trebuie să aveți cel puțin 13 ani pentru a utiliza Stripe.",
        divider: "Despărțitor",
        installmentPayments: "Tranșe de plată",
        onlyOne: "Doar una",
        companyOverview: "Prezentarea generală a companiei",
        companyDetails:
          "Vă rugăm să completați informațiile companiei în opțiuni.",
        additionalData: "Date adiționale",
        noPayments: "Nu sunt plăți.",
        noPayouts: "Nu sunt plăți.",
        generatePdf: "Generați PDF",
        pleaseSelectServicesAndPackage:
          "Vă rugăm să selectați serviciile și pachetul.",
        moveServicesInNewCategory: "Mutați serviciile în altă categorie.",
        changeAllServicePrices: "Modificați toate prețurile serviciilor.",
        addServicesInselectedPackage:
          "Adăugați serviciile în pachetul selectat.",
        saveMessageAsTemplate: "Salvați mesajul ca șablon pentru viitor.",
        addressNotFound: "Adresa nu a fost gasită.",
        takeAPhoto: "Faceți o poză.",
        workInConstruction: "Lucrați în construcții?",
        type: "Tip",
        administrative: "Cost administrativ",
        purchase: "Cumpărare",
        workforce: "Mâna de lucru",
        sumInCash: "Suma primită cash",
        create: "Crează",
        close: "Închide",
        selectServices: "Selectați-vă serviciile",
        pleaseselectServices: "Vă rugăm să selectați serviciile.",
        insertCompanyInfo:
          "Vă rugăm să compleatați informațiile despre companie.",
        insertSupplier: "Vă rugăm să introduceți un furnizor.",
        selectClient: "Vă rugăm să selectați un client.",
        budgetEstimate: "Estimarea bugetului",
        invalid: "invalid",
        valid: "valid",
        confirmContract: "Trebuie să adăugați un șablon pentru contract.",
        tryAgain: "Încearca din nou.",
        color: "Culoare",
        curency: "Valută",
        reminderNotificationMinAmount: "Valoare minimă pentru notificări",
        reminderDelay: "Interval de amintire neplată.",
        notificationDelay: "Întârzierea notificărilor",
        noNewNotifications: "Nici-o notificare nouă",
        continuousInvoiceNumbers:
          "Numerele facturilor sunt resetate anual la 1.",
        generatedWith: "Generată cu",
        currentPassword: "Parola actuală",
        pleaseSelect: "Vă rugăm să selectați: ",
        selectParentCategory: "Selectați categoria părinte.",
        signBelow: "Semnați mai jos",
        saveSignature: "Salvați semnătura",
        erase: "Ștergeți",
        pressToSign: "Apăsați pentru a semna.",
        termsAndConditions: "Am citit și accept termenii și condițiile.",
        confirm: "Confirmați",
        accept: "Accept",
        minOneLetterAndNumber:
          "Parola trebuie să conțină cel puțin o literă si o cifră.",
        min8Character: "Parola trebuie să conțină cel puțin 8 caractere.",
        emailNotValid: "E-mailul introdus nu este un e-mail valid.",
        fieldRequired: "Acest câmp este obligatoriu.",
        passwordMismatch: "Parolele introduse nu corespund.",
        cancel: "Anulare",
        disableAccount: "Sunteți sigur că doriți să dezactivați acest cont?",
        saveService: "Sunteți sigur că doriți să salvați acest serviciu?",
        yes: "Da",
        no: "Nu",
        register: "Înregistrare",
        back: "Înapoi",
        design: "Aspect",
        about: "Despre",
        workPerHour: "Lucru la oră",
        perDay: "la zi",
        perHour: "la oră",
        inSelectedInterval: "În intervalul selectat",
        toLargeOrWrongFormat:
          "Fișierul este prea mare sau formatul este incompatibil.",
        badpayee: "Rau platnic",
        success: "Succes",
        successfullyCreated:
          "Contul dumneavoastră Stripe a fost creat cu succes.",
        successfullyDisabled: "Contul dumneavoastră Stripe este dezactivat.",
        modifyPrices: "Modificați prețurile",
        pricePerHour: "Preț la oră",
        trimester: "Trimestrul",
        withBreak: "Minus pauză",
        addAnnualCost: "Adăugați costul anual",
        dailyBreak: "Pauză zilnică",
        minutes: "minute",
        stop: "Stop",
        available: "Disponibil",
        pending: "În așteptare",
        balance: "Bilanț",
        industry: "Industrie",
        verifyIdentityDocument: "Verificați actul de identitate.",
        verifyHomeAddress: "Verificați adresa de acasă.",
        payouts: "Plăți",
        personalIdNumber: "CNP",
        familyName: "Nume de familie",
        dateOfBirth: "Data nașterii",
        bancontact: "Bancontact",
        modifyPricesByPercent: "Modificați prețurile cu un procent.",
        enable: "Activați",
        enableOnlinePayments: "Activați plata online",
        vatNoLength: 8,
        serviceName: "Vă rugăm să introduceți numele serviciului",
        minAmount: "Suma minimă",
        series: "Serie",
        monthly: "Lunar/a",
        ok: "Ok",
        upgrade: "Upgrade",
        subscription: "Abonament",
        rename: "Redenumește",
        import: "Importă",
        getSubscription: "Ia un abonament",
        upgradeSubscription: "Actualizează abonamentul",
        doc: "Document",
        day: "zi",
        trial: "Perioada de probă",
        finish: "Termină",
        linkCopied: "Link-ul a fost copiat",
        next: "Înainte",
        tutWelcomeTitle: "Bine ați venit!",
        tutWelcomeContent:
          "Acesasta este o foarte scurtă prezentare a interfaței, pentru a fi productiv în cât mai scurt timp.",
        tutLeftMenuTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Meniul principal",
        tutLeftMenuContent:
          "În meniul principal se găsesc toate documentele create, rapoarte, se pot manageria diferite tipuri de entităti, cât și date detaliate despre companie.",
        tutNewDocumentTitle: "Creați un document sau un dosar nou.",
        tutNewDocumentContent:
          "Oriunde ați fi în aplicație, de aici puteți crea un document sau un dosar nou. <span class='glyphicon glyphicon-chevron-right'></span>",
        tutNotoficationsTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Notificări",
        tutNotoficationsContent:
          "Aici o sa puteți vedea notificările generate de aplicație.",
        tutOptionsTitle:
          "Opțiuni și deconectare. <span class='glyphicon glyphicon-chevron-right'></span>",
        tutOptionsContent:
          "Din partea dreapta a interfeței puteți accesa opțiunile si puteți închide aplicația.",
        tutDocumentInfoTitle: "Document nou.",
        tutDocumentInfoContent:
          "Documentul are două părți, una pentru informații generale și una pentru servicii și produse. Informațiile de bază se află în partea de sus a interfeței. Pentru a salva documentul, faceți clic pe butonul Save (Salvare).",
        tutDocumentDetailsTitle: "Adăugați servicii și produse.",
        tutDocumentDetailsContent:
          "Aici puteți adăuga servicii și produse făcând clic pe butonul + din partea de sus sau adăugând primul produs din meniul din dreapta. Acestea vor salva automat documentul creat anterior.",

        foldersTutBasicInfo:
          "Aici puteți modifica informațiile din dosarul clientului și locația în care sunt furnizate serviciile.",
        foldersTutInvoices: "Venituri. Facturi proforme și note de credit.",
        foldersTutInvoicesDes:
          "Creați un nou document pe baza filei selectate. Apăsați butonul + (plus) pentru a crea un nou document în proiectul activ. Acestea vor fi, de asemenea, vizibile în lista tuturor documentelor (facturi, proforme ...). Dacă ați adăugat o factură greșită la proiect, o puteți deselecta selectând-o și apăsând butonul - (minus). În partea de jos, aveți informații sumare despre documentele din foaia de lucru selectată.",
        foldersTutOrderTickets:
          "Bonuri de comandă și documente pentru subcontractanți.",
        foldersTutCostsDes:
          "Adaugați costuri legate de proiect pentru a ști tot timpul situația financiară a acestuia.",
        foldersFileExplorerDes:
          "Documentele de proiect trimise prin e-mail vor fi, de asemenea, salvate în format PDF aici. Puteți adăuga înregistrări legate de servicii pentru a obține toate informațiile într-un singur loc și pentru a evita problemele legate de confuzia clienților.",

        docTutBasicInfo:
          "Editați documentul și informațiile despre client aici.",
        docTutWorkloadSubdivision:
          "Pentru a crea documente, puteți crea subdiviziuni care se vor încadra în serviciile furnizate.",
        docTutCategories:
          "În cadrul acestor subdiviziuni, serviciile sunt împărțite în categorii și subcategorii. Serviciile pe care le creați vor fi plasate în categoria și subdiviziunea aleasă. Dacă acestea nu sunt alese, serviciile sunt adăugate la categoria și subdiviziunea implicită.",
        docTutAddDeleteService:
          "Butonul + (plus) adaugă un nou serviciu la lista de servicii. Butonul de lângă acesta (bin) elimină serviciile selectate din document.",
        docTutCategoryDescription:
          "Categoriile documentului pot avea o descriere mai amplă. Aici poate fi scrisă și salvată apăsând butonul din dreapta.",
        docTutService:
          "Serviciul este inițial gol. Prin completarea numelui, puteți selecta din lista de sugestii de servicii salvate anterior. Dacă nu selectați nimic, creați unul nou. Serviciile sunt salvate automat atunci când treceți la câmpul următor.",
        docTutServiceDetails:
          "Noile servicii pot fi modificate în detaliu făcând clic pe butonul de opțiuni ... (trei puncte). După modificare, aceasta este salvată automat în document, dar poate fi actualizată în baza de date a serviciului cu ajutorul butonului Salvare, în dreapta sus.",
        docTutTotals:
          "În partea de jos, totalurile sunt actualizate automat cu serviciile adăugate.",
        docTutDocumentPreview:
          "Butonul de previzualizare generează documentul în format imprimabil. Acesta poate fi previzualizat, tipărit, trimis prin e-mail către client sau comentat.",
        docTutBlockUnblock:
          "Butoanele de blocare/ deblocare pot bloca toate câmpurile din document, astfel încât acestea să nu poată fi modificate.",
        docTutOtherOptions:
          "În funcție de documentul din meniu, apar alte opțiuni specifice pentru fiecare tip de document.",

        createRecurrent:
          "Factura este generată automat la intervalul de zile ales. Doriți să continuați?",
        recurrenceInterval: "Interval recurent (Zile)",
        recurrentInvoice: "Factură recurentă",
        paymentDeadline: "Termen de plată",
        withoutVat: "Fără TVA",
        notUsed: "Nefolosite",
        dashboard: "Panou de control",
        choosePlan: "Alegeți planul",
        chooseMonth: "Alegeți luna",
        sepaTerms:
          "Prin semnarea acestui formular de mandat, autorizați Cronoptimal să trimită instrucțiuni băncii dvs. pentru a vă debita contul, iar banca dvs. să vă debiteze contul în conformitate cu instrucțiunile Cronoptimal. Aveți dreptul la rambursare din partea băncii dvs. în condițiile descrise în acordul pe care l-ați petrecut cu ea. Toate cererile de rambursare trebuie să fie depuse în termen de 8 săptămâni de la data la care contul dvs. a fost debitat. Vă rugăm să completați câmpurile marcate cu *.",
        pay: "Plătiți",
        youChoose: "Ați ales",
        profit: "Profit",
        overview: "Sinteza",
        from: "din",
        secondTo: "până în",

        January: "Ianuarie",
        February: "Februarie",
        March: "Martie",
        April: "Aprilie",
        May: "Mai",
        June: "Iunie",
        July: "Iulie",
        August: "August",
        September: "Septembrie",
        October: "Octombrie",
        November: "Noiembrie",
        December: "Decembrie",

        clientTel: "Telefon Client",
        clientEmail: "E-mail Client",
        header: "Antet",
        footer: "Subsol",
        default: "Implicit",
        userDefined: "Definite de utilizator",
        hour: "Ora",
        actions: "Acțiuni",
        bulkActions: "Acțiuni multiple",
        spent: "Cheltuit",
        deleteAssociated: "Documentele asociate vor fi șterse.",
        newDocument: "Document nou",
        invoice: "Factură",
        paid: "Plătită",
        unpaid: "Neplătită",
        payment: "Plată",
        proforma: "Factură proforma",
        creditNote: "Notă de credit",
        creditNotes: "Note de credit",
        reminder: "Reamintire",
        number: "Număr",
        date: "Dată",
        amount: "Sumă",
        elapsedTime: "Interval trecut",
        days: "Zile",
        notice: "Notificare",
        page: "Pagină",
        preview: "Previzualizare",
        previewSend: "Previzualizare și trimitere",
        update: "Actualizare",
        attestation: "Atestare",
        totalNoVat: "Total fără TVA",
        totalWithVat: "Total cu TVA",
        restToPay: "Rest de plata",
        gift: "Cadou",
        orderTicket: "Bon de comandă",
        notsaved: "Nesalvat",
        saved: "Salvat",
        selectImages: "Selectați imaginile",
        nothingSelected: "Nimic nu este selectat.",
        deleteItem: "Sigur vreți să ștergeți elementul?",
        original: "Original",
        add: "Adaugați",
        modify: "Modificați",
        quantity: "Cantitate",
        name: "Nume",
        unitPrice: "Preț pe unitate",
        options: "Opțiuni",
        description: "Descriere",
        materialsCost: "Cost materiale",
        laborCost: "Cost mâna de lucru",
        laborTime: "Timp de lucru",
        articles: "Articole",
        article: "Articol",
        workloadSubdivision: "Subdiviziuni muncă",
        totalMerchandisesNoVat: "Cost total",
        totalPayment: "Total plată",
        leftToPay: "Rest plată",
        vat: "TVA",
        documents: "Documente",
        lastDocuments: "Ultimele documente",
        estimates: "Devize",
        orderTickets: "Bonuri de comandă",
        invoices: "Facturi",
        received: "Primit",
        suplementSheet: "Foaie de supliment",
        companyContract: "Contract companie",
        vatAttestation: "Atestare TVA",
        pvTemporarReception: "PV primire temporară",
        pvDefinitiveReception: "PV primire definitivă",
        subcontractorContract: "Contract subcontractanți",
        heatingWorkTicket: "Bon de comandă de încălzire",

        generalConditions: "Condiții generale",

        receipts: "Chitanțe",
        proformas: "Proforme",
        newProforma: "Proformă nouă",

        disabled: "Dezactivat",
        disable: "Dezactivați",
        remove: "Eliminați",
        delete: "Ștergeți",
        select: "Selectați",
        save: "Salvați",
        contracts: "Contracte",
        extraWorkReports: "Raport extra muncă",
        extraCostsReports: "Raport extra costuri",
        attachedFiles: "Fișiere atașate",
        attestationDocuments: "Atestări",
        documentTemplates: "Șabloane documente",
        payments: "Plăti",
        onlinePayments: "Plăti online",
        vatRate: "Rată TVA",
        client: "Client",
        selectAValue: "Selectați o valoare",
        addSelected: "Adăugați elementele selectate",
        edit: "Modificați",
        extra: "Extra",
        service: "Serviciu",
        currency: "Monedă",
        status: "Status",
        observations: "Observații",
        firstName: "Prenume",
        email: "E-mail",
        webPage: "Pagină web",
        postalCode: "Cod poștal",
        logo: "Sigla",
        address: "Adresă",
        city: "Oraș",
        country: "Țară",
        code: "Cod",
        company: "Companie",
        image: "Imagine",
        // folders
        folder: "Proiect",
        folders: "Proiecte",
        folderNo: "Nr. Dosar",
        clientAddress: "Adresă client",
        startDate: "Dată de începere",
        endDate: "Dată de finalizare",
        folderOptions: "Opțiuni dosar",
        newFolder: "Dosar nou",
        basicInfo: "Info de bază",
        details: "Detalii",
        extraCosts: "Extra costuri",
        other: "Altele",
        modifyFolder: "Modifică dosar",
        constructionType: "Tip constructie",
        dateOfPayment: "Data plătii",
        addPayment: "Adaugă plata",
        addExtraCost: "Adaugă cost extra",
        paymentNote: "Notă de plată",
        finalPaymentNote: "Notă finală",
        title: "Titlu",
        owner: "Proprietar",

        // document Templates
        estimatesTemplate: "Șablon deviz",
        orderTicketTemplate: "Șablon bon de comanda",
        invoiceTemplate: "Șablon factură",
        receiptTemplate: "Șablon chitanță",
        contractTemplate: "Șablon contract",
        extraWorkReportTemplate: "Șablon raport extra muncă",
        extraCostsReportTemplate: "Șablon raport extra costuri",
        attestationTemplate: "Șablon atestare",
        template: "Șablon",

        // documents
        lastInvoices: "Ultimile facturi",
        lastEstimates: "Ultimile devize",
        lastorderTickets: "Ultimile bonuri de comanda",
        lastorderDocuments: "Ultimile documente",

        // receipts
        newReceipt: "Chitanță nouă",
        receipt: "Chitanță",

        // order tickets
        newOrderTicket: "Bon de comanda nou",

        // invoices
        newInvoice: "Factură nouă",
        invoicesOptions: "Opțiuni facturi",

        // reports
        newReport: "Raport nou",

        //estimates
        estimate: "Deviz",
        newEstimate: "Deviz nou",
        estimateNo: "Număr deviz",
        estimatesOptions: "Opțiuni devize",

        // contracts
        newContract: "Contract nou",

        // attestation documents
        newAttestationDoc: "Document atestare nou",

        // companies
        companies: "Companii",
        newCompany: "Companie nouă",
        companyUsers: "Utilizatorii companiei",
        addNewUser: "Adăugați un utilizator nou",
        companyInfo: "Date companie",
        vatNo: "Număr TVA",
        vatType: "Tip TVA",
        companyRegNo: "Număr înregistrare companie",
        bankBic: "Cod BIC bancă",
        bankName: "Nume bancă",
        online: "Online",
        bankAccount: "Cont bancar",
        bankTransfer: "Transfer bancar",
        account: "Cont",
        telFax: "Telefon/Fax",
        mobile: "Mobil",
        tel: "Telefon",
        fax: "Fax",
        secondary: "Secundar",

        // clients
        clients: "Clienți",
        newClient: "Client nou",
        clientName: "Nume client",
        clientDiscount: "Reducere client",
        contactPerson: "Persoană de contact",
        clientInfo: "Informații client",
        contact: "Contact",

        // categories
        category: "Categorie",
        categories: "Categorii",
        subcategory: "Subcategorie",

        // services
        services: "Servicii",
        measureUnit: "Unitate măsură",
        price: "Preț",
        cost: "Cost",
        tradeMargin: "Adaos comercial (%)",
        newService: "Serviciu nou",
        addServiceArticles: "Adaugați articole serviciului",

        // articles
        brand: "Marcă",
        diameter: "Diametru",
        newArticle: "Articol nou",
        coefficient: "Coeficient",
        updateFromFile: "Actualizare din fișier",
        updateFromWebpage: "Actualizare de pe site",
        productsFile: "Fișier produse",
        imagesFile: "Fișier imagini",
        articleGroup: "Grup articole",

        subcontractors: "Subcontractanți",
        subcontractors_employees: "Angajații subcontractanți",
        company_employees: "Angajații companiei",
        newSubcontractor: "Subcontractant nou",
        subcontractor: "Subcontractant",
        subcontractorAddress: "Adresă subcontractant",
        parent: "Părinte",
        totalPrice: "Preț total",
        totalHours: "Numărul total de ore",
        siteAddress: "Adresă de șantier",
        workSite: "Șantier",
        sameAsClient: "Identic cu adresa client",
        emptyLine: "Linie goală",
        value: "Valoare",
        content: "Conținut",
        paymentDue: "Termen plată",
        paymentDate: "Termen plată",
        paymentMode: "Mod plată",
        paymentMethod: "Mod plată",
        bank: "Bancă",
        cash: "Numerar",
        downPayment: "Acont",
        discount: "Reducere",
        amountNoVat: "Suma fără TVA",
        amountWithVat: "Total",
        message: "Mesaj",
        attachment: "Atașament",
        emailSignature: "Semnatură e-mail",
        subject: "Subiect",
        to: "Către",
        generateInvoice: "Generați factură",
        active: "Activ",
        inactive: "Inactiv",
        all: "Toți",
        year: "An",
        addYear: "Adăugați an",
        addNewBudgetEstimate: "Adăugați estimare buget",
        filter: "Filtru",
        total: "Total",
        generate: "Generați",
        timeElapsed: "Timp scurs",
        start: "Început",
        middle: "Mijloc",
        end: "Sfârșit",
        work: "Muncă",
        signature: "Semnatură",
        reminderEmailSignature: "Semnătură e-mail notificare",
        estimateEmailSignature: "Semnatură e-mail deviz",
        orderTicketEmailSignature: "Semnatură e-mail bon de comandă",
        startWork: "Început lucrare",
        duringWork: "În timpul lucrării",
        endWork: "Final lucrare",
        gallery: "Galerie",
        expenses: "Cheltuieli",
        expense: "Cheltuială",
        upload: "Încarca",
        send: "Trimite",
        new: "Nou",
        reset: "Resetează",
        reimburse: "Stornare",
        subcontractorOrderTickets:
          "Bonuri de comandă subcontractanți / angajați",
        subcontractorOrderTicket: "Bon de comandă subcontractant / angajat",
        employeesOrderTickets: "Bonuri de comandă angajati",
        single: "Individual",
        copy: "Copiere",
        paste: "Lipire",
        accounting: "Contabilitate",
        existing: "Existent",
        none: "Nici unul",
        contract: "Contract",
        employee: "Angajat",
        employees: "Angajați",
        lastLocationToday: "Ultimă localizare astăzi",
        locationAlert: "Alertă locație",
        outOfRange: "În afara intervalului",
        search: "Căutare",
        alerts: "Alerte",
        alertInfo: "Informații alertă",
        reports: "Rapoarte",
        clocking: "Pontaj",
        login: "Autentificare",
        logout: "Delogare",
        username: "Utilizator",
        password: "Parolă",
        newPassword: "Parolă noua",
        repeatPassword: "Repetați parola",
        repeatNewPassword: "Repetați noua parolă",
        role: "Funcție",
        rememberMe: "Ține-mă minte",
        forgotPassword: "Am uitat parola",
        passwordsDontMatch: "Parolele nu se potrivesc.",
        usernameExists: "Acest nume de utilizator există deja.",

        //errors
        compulsoryUserPassword: "Sunt necesare numele de utilizator și parola.",
        wrongUserOrPassword: "Numele de utilizator sau parola sunt greșite.",

        // client errors
        clientExists: "Clientul deja există.",
        clientDoesNotExist: "Clientul nu există.",

        // category errors
        categoryExists: "Categoria deja există.",
        categoryStillUsed:
          "Categoria este folosită de servicii. Ștergeți mai întâi serviciile care îi aparțin.",
        deleteCategory:
          "Confirmați ștergerea categoriei cu toate serviciile conținute?",

        // services
        ordersWithServiceExist:
          "Directoare ce contin serviciul există. Ștergeți mai întâi directoarele dependente.",
        serviceDoesNotExists: "Serviciul nu există.",
        summaryEstimates: "Rezumat estimări",
        // estimates
        estimateWithSameNumberExists: "Un deviz cu același număr există deja.",
        estimateDoesNotExists: "Devizul nu există.",

        invoiceWithSameNumberExists: "O factură cu același număr există deja.",
        sameNumberExists: "Numarul deja există.",

        // general errors
        notFound: "Nu a fost găsit",
        error: "Eroare",
        selectError: "Vă rugăm să selectați un element din listă.",
        saveBaseService: "Înregistrați și înlocuiți serviciul de bază?",
        noInitialServiceFound: "Nu a fost definit un serviciu inițial.",
        move: "Mută",
        moving: "În mișcare",
        summary: "Rezumat",
        locate: "Localizează",
        useLocation: "Folosește locația",
        blocked: "Blocat",
        unblocked: "Deblocat",
        hidePrices: "Ascunde prețurile",
        totalToInvoice: "Total de facturat",
        amountLeftToInvoice: "Total rămas de facturat",
        totalAuthorizedToInvoice: "Total autorizat pentru facturat",
        alreadyInvoiced: "Deja facturat",
        creditCard: "Card credit/debit",
        dragFiles: "Trageți fișierele aici",
        open: "Deschis",
        closed: "Închis",
        liability: "Datorii",
        minValue: "Valoare minimă",
        maxValue: "Valoare maximă",
        docType: "Tip document",
        paymentTerm: "Termen de plată",
        notifications: "Notificări",
        notification: "Notificare",
        supplier: "Furnizor",
        equivalent: "Contravaloare",
        alreadyPaid: "Deja plătit",
        costs: "Costuri",
        presenceOfContractor:
          "În prezența contractantului (sau a reprezentantului acestuia)",
        presenceOfDeveloper: "În prezența managerului de proiect",
        absenceOfContractor:
          "În absența contractantului, convocat în mod corespunzător prin scrisoare recomandată cu confirmare de primire",
        contractor: "Contractant",
        developer: "Dezvoltator",
        assistant: "Asistent",
        warranty: "Garanție",
        actualCosts: "Costuri reale",
        estimatedCosts: "Costuri estimate",
        omniumWarranty:
          "Omnium - Acesta acoperă totul: deplasările, salariile, piese de schimb pentru înlocuire",
        regularWarranty:
          "Garanție - Numai partea defectă va fi înlocuită în mod gratuit. Tarifele pe oră și de deplasarea trebuie să fie plătite",
        digressiveWarranty:
          "Garanția degresiva - La fel ca si garantia normala, cu excepția faptului că, după un număr de ani, se va plăti  și un anumit procent din prețul pieselor defecte.",
        unauthorized: "Accesul nu este permis",
        addText: "Adaugă text",
        groupName: "Nume grup",
        duplicate: "Duplicat",
        optional: "Optional",
        invoicing: "Facturare",
        costManagement: "Management costuri",
        representativeName: "Nume reprezentant",
        generalInfo: "Informații generale",
        bankInfo: "Informații bancare",
        admin: "Administrator",
        timekeeping: "Pontaj",
        map: "Hartă",
        clientType: "Tipul de client",
        tenant: "Chiriaș",
        usufructuary: "Uzufructuar",
        manager: "Manager",
        packages: "Pachete",
        package: "Pachet",
        docNo: "Număr document",
        companyName: "Nume companie",
        companyAddress: "Adresă companie",
        clientNo: "Cod client",
        clientVatNo: "CIF client",
        up: "Sus",
        upgradeForMoreClients:
          "Moficați planul de abonament pentru mai mulți clienți.",
        reconnect:
          "A apărut o eroare în timpul recuperării datelor. Vă rugăm să vă conectați din nou.",
      },

      fr: {
        january: "Janvier",
        february: "Février",
        march: "Mars",
        april: "Avril",
        may: "Mai",
        june: "Juin",
        july: "Juillet",
        august: "Août",
        september: "Septembre",
        october: "Octobre",
        november: "Novembre",
        december: "Décembre",
        invalidDate: "Date invalide",
        home: "Maison",
        showMore: "Montrer plus",
        showLess: "Montrer moins",
        estimatedTotal: "Total estimé",
        actualTotal: "Total réel",
        offer: "Devis",
        months: "Mois",
        navigation: "Navigation",
        noActivity: "Aucune activité détectée. Vous serez déconnecté sous peu.",
        noEmployees: "Aucun employé disponible.",
        hr: "Ressources humaines",
        additionalInformation: "Informations complémentaires",
        emailNotConfirmed: "L'adresse e-mail n'a pas encore été confirmée.",
        pleaseConfirmEmail:
          "Un e-mail de confirmation a été envoyé à l'adresse fournie.",
        confirmEmail: "Veuillez confirmer votre email pour continuer.",
        confirmedEmail: "Votre e-mail a été confirmé.",
        resendEmail: "Renvoyer l'email",
        receiveCC: "Ajouter en cc",
        noDisposableEmail: "Les e-mails jetables ne sont pas autorisés.",
        vatValidationErr: "La validation du numéro de TVA introduit a échoué.",
        export: "Exportation",
        size: "Taille",
        section: "Section",
        container: "Récipient",
        embed: "Incorporer",
        ascending: "Ascendant",
        descending: "Descendant",
        sort: "Trier",
        language: "fr",
        reference: "Référence",
        loading: "Chargement en cours...",
        warning: "Attention",
        alreadySelected: "Déjà sélectionné",
        requiredColumns: "Colonnes obligatoires",
        noData: "Aucune donnée",
        report: "Rapport",
        userRoles: "Rôles d'utilisateur",
        atLeast13years:
          "Vous devez avoir au moins 13 ans pour utiliser Stripe.",
        divider: "Diviseur",
        installmentPayments: "Paiements échelonnés",
        onlyOne: "une seule",
        companyOverview: "Présentation de l'entreprise",
        companyDetails: "Veuillez saisir les coordonnées de l'entreprise.",
        additionalData: "Donnée supplémentaire",
        noPayments: "Pas de paiements",
        noPayouts: "Aucun paiement",
        generatePdf: "Générer PDF",
        pleaseSelectServicesAndPackage:
          "Veuillez sélectionner les services et le paquet.",
        moveServicesInNewCategory:
          "Déplacer les services dans une nouvelle catégorie.",
        changeAllServicePrices: "Modifier tous les prix des services.",
        addServicesInselectedPackage:
          "Ajouter les services dans le paquet sélectionné.",
        saveMessageAsTemplate:
          "Enregistrer le message comme modèle pour une utilisation future.",
        addressNotFound: "Adresse introuvable",
        takeAPhoto: "Prendre une photo",
        workInConstruction: "Travaillez-vous dans le bâtiment?",
        type: "Type",
        administrative: "Coût administratif",
        purchase: "Achats",
        workforce: "Main d'oeuvre",
        sumInCash: "REÇU LA SOMME EN ESPÈCES",
        create: "Créer",
        close: "Fermer",
        selectServices: "Veuillez sélectionner vos prestations.",
        pleaseselectServices: "Veuillez sélectionner les services.",
        insertCompanyInfo: "Insérer toutes les informations sur l'entreprise.",
        insertSupplier: "Veuillez insérer un fournisseur.",
        selectClient: "Veuillez sélectionner un client.",
        budgetEstimate: "Estimation budgétaire",
        invalid: "invalide",
        valid: "valide",
        confirmContract: "Vous devez ajouter un modèle pour le contrat.",
        tryAgain: "Réessayer",
        color: "Couleur",
        curency: "Devise",
        reminderNotificationMinAmount: "Valeur minimale pour la notification",
        reminderDelay: "Délais de rappel",
        notificationDelay: "Délais de notification",
        noNewNotifications: "Pas de nouvelles notifications",
        continuousInvoiceNumbers:
          "Les numéros de facture sont réinitialisés chaque année à 1.",
        generatedWith: "Généré avec",
        currentPassword: "Mot de passe actuel",
        pleaseSelect: "Veuillez sélectionner: ",
        selectParentCategory: "Sélectionner la catégorie parente",
        signBelow: "Signez ci-dessous",
        saveSignature: "Enregistrer la signature",
        erase: "Effacer",
        pressToSign: "Appuyez ici pour signer",
        termsAndConditions: "J'ai lu et j'accepte les termes et conditions.",
        confirm: "Confirmer",
        accept: "Accepter",
        minOneLetterAndNumber:
          "Le mot de passe doit contenir au moins une lettre et un chiffre.",
        min8Character: "Le mot de passe doit contenir au moins 8 caractères.",
        emailNotValid: "L'email introduit n'est pas valide.",
        fieldRequired: "Ce champ est requis.",
        passwordMismatch:
          "Les de mots de passe introduits ne correspondent pas.",
        cancel: "Annuler",
        disableAccount: "Êtes-vous sûr de vouloir désactiver ce compte?",
        saveService: "Voulez-vous vraiment enregistrer ce service?",
        yes: "Oui",
        no: "Non",
        register: "Registre",
        back: "Retour",
        design: "Design",
        about: "À propos",
        workPerHour: "Travail en régie",
        perDay: "Par jour",
        perHour: "Par heure",
        inSelectedInterval: "Dans l'intervalle sélectionné",
        toLargeOrWrongFormat:
          "Le fichier est trop volumineux ou le format est incompatible.",
        badpayee: "Mauvais payeur",
        success: "Succès",
        successfullyCreated: "Votre compte Stripe a été créé avec succès.",
        successfullyDisabled: "Votre compte Stripe a été désactivé.",
        modifyPrices: "Modifier les prix",
        pricePerHour: "Prix par heure",
        trimester: "Trimestre",
        withBreak: "Moins pause",
        addAnnualCost: "Ajouter un coût annuel",
        dailyBreak: "Pause quotidienne",
        minutes: "minutes",
        stop: "Arrêter",
        available: "Disponible",
        pending: "En attendant",
        balance: "Solde du compte",
        industry: "Industrie",
        verifyIdentityDocument: "Vérifier la pièce d'identité",
        verifyHomeAddress: "Vérifier l'adresse du domicile",
        payouts: "Paiements",
        personalIdNumber: "Numéro d'identification personnel",
        familyName: "Nom de famille",
        dateOfBirth: "Date de naissance",
        bancontact: "Bancontact",
        modifyPricesByPercent: "Modifier les prix par pourcentage.",
        enable: "Activer",
        enableOnlinePayments: "Activer les paiements en ligne",
        vatNoLength: 11,
        serviceName: "Veuillez saisir le nom du service.",
        minAmount: "Montant minimal",
        series: "Séries",
        monthly: "Mensuel",
        ok: "Ok",
        upgrade: "Mise à niveau",
        subscription: "Abonnement",
        rename: "Renommer",
        import: "Importer",
        getSubscription: "Recevoir un abonnement",
        upgradeSubscription: "Mettre à jour votre abonnement",
        doc: "Document",
        day: "journée",
        trial: "Période d'essai",
        finish: "Terminer",
        linkCopied: "Lien copié",
        next: "Suivant",
        tutWelcomeTitle: "Bienvenue!",
        tutWelcomeContent:
          "Ceci est un très bref aperçu de l'interface pour être productif dans les plus brefs délais.",
        tutLeftMenuTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Le menu principal.",
        tutLeftMenuContent:
          "Dans le menu principal, vous trouverez tous les documents créés. Les rapports peuvent fournir différents types d'analyses, ainsi que des informations détaillées sur l'entreprise.",
        tutNewDocumentTitle: "Créez un nouveau document ou un nouveau projet.",
        tutNewDocumentContent:
          "Où que vous soyez dans l'application, ici vous pouvez créer un nouveau document ou un nouveau projet. <span class='glyphicon glyphicon-chevron-right'></span>",
        tutNotoficationsTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Notifications.",
        tutNotoficationsContent:
          "Ici, vous pouvez voir les notifications générées par l'application.",
        tutOptionsTitle:
          "Options et déconnexion. <span class='glyphicon glyphicon-chevron-right'></span>",
        tutOptionsContent:
          "Du côté droit de l'interface, vous pouvez accéder aux options et vous pouvez fermer l'application.",
        tutDocumentInfoTitle: "Nouveau document.",
        tutDocumentInfoContent:
          "Le document comporte deux parties, une pour les informations générales et une pour les services et produits. Les informations de base sont en haut de l'interface. Pour enregistrer le document cliquez sur le bouton Enregistrer.",
        tutDocumentDetailsTitle: "Ajouter des produits et services.",
        tutDocumentDetailsContent:
          "Ici, vous pouvez ajouter des services et des produits en cliquant sur le bouton + en haut ou en ajoutant le premier produit à partir du menu à droite. Ils enregistreront automatiquement le document créé précédemment.",

        foldersTutBasicInfo:
          "Ici, vous modifiez les informations dans le fichier client et l'endroit où les services sont livrés.",
        foldersTutInvoices: "Revenus. Factures proforma et notes de crédit.",
        foldersTutInvoicesDes:
          "Créez un nouveau document en fonction de l'onglet sélectionné. Appuyez sur le bouton + (plus) pour créer un nouveau document dans le projet actif. Ceux-ci seront également visibles dans la liste de tous les documents (factures, proforma ...). Si vous avez ajouté une mauvaise facture au projet, vous pouvez la dissocier en la sélectionnant et en appuyant sur le bouton - (moins). En bas, vous disposez d'informations récapitulatives sur les documents de la feuille de calcul sélectionnée.",
        foldersTutOrderTickets: "Reçus et documents pour les sous-traitants.",
        foldersTutCostsDes:
          "Ajoutez des coûts liés au projet pour suivre la situation financière du dossier.",
        foldersFileExplorerDes:
          "Les documents du projet envoyés par courrier électronique seront également enregistrés en format PDF ici. Vous pouvez ajouter des enregistrements liés au service pour obtenir toutes les informations au même endroit et éviter les problèmes de confusion de clientèle.",

        docTutBasicInfo:
          "Modifiez ici le document et les informations sur le client.",
        docTutWorkloadSubdivision:
          "Pour créer des documents, vous pouvez créer des subdivisions qui s'intégreront dans les services fournis.",
        docTutCategories:
          "Dans ces subdivisions, les services sont divisés en catégories et sous-catégories. Les services que vous créez seront placés dans la catégorie et la subdivision choisie. Si ceux-ci ne sont pas choisis, les services sont ajoutés à la catégorie et la subdivision par défaut.",
        docTutAddDeleteService:
          "Le bouton + (plus) ajoute un nouveau service à votre liste de services. Le bouton à côté (poubelle) supprime les services sélectionnés du document.",
        docTutCategoryDescription:
          "Les catégories du document peuvent avoir une description plus large. Ici, elle peut être écrite et enregistrée en appuyant sur le bouton à droite.",
        docTutService:
          "Le service est d'abord vide. En remplissant le nom, vous pouvez sélectionner dans la liste des suggestions des services précédemment enregistrées. Vous en créez un nouveau si vous ne choisissez rien. Les services sont sauvegardés automatiquement lors du passage au champ suivant.",
        docTutServiceDetails:
          "Les nouveaux services peuvent être modifiés en détail en cliquant sur le bouton options ... (trois points). Après la modification, il est automatiquement enregistré dans le document, mais il peut être mis à jour dans la base de services par le bouton de Enregistrer, en haut à droite.",
        docTutTotals:
          "En bas, les totaux sont automatiquement mis à jour en fonction des services ajoutés.",
        docTutDocumentPreview:
          "Le bouton de prévisualisation génère le document au format imprimable. Il peut être prévisualisé, imprimé, envoyé par e-mail au client ou annoté.",
        docTutBlockUnblock:
          "Les boutons de verrouillage / déverrouillage peuvent bloquer tous les champs du document afin qu'ils ne puissent plus être modifiés.",
        docTutOtherOptions:
          "Selon le document dans le menu, d'autres options spécifiques apparaissent à chaque type de document.",

        createRecurrent:
          "La facture est générée automatiquement à l'intervalle de jours choisi. Voulez-vous continuer?",
        recurrenceInterval: "Intervalle de récurrence (Jours)",
        recurrentInvoice: "Facture récurrente",
        paymentDeadline: "Délais de paiement",
        withoutVat: "Hors TVA",
        notUsed: "Inutilisé",
        dashboard: "Tableau de bord",
        choosePlan: "Choisir un forfait",
        chooseMonth: "Choisir un mois",
        sepaTerms:
          "En signant ce formulaire de mandat, vous autorisez Cronoptimal à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de Cronoptimal. Vous avez un droit de remboursement par votre banque dans les conditions décrites dans l'accord que vous avez passé avec elle. Toutes les demandes de remboursement doivent être soumises dans les 8 semaines suivant la date à laquelle votre compte a été débité. Veuillez remplir les champs marqués d'un *.",
        pay: "Payer",
        youChoose: "Vous avez choisi le",
        profit: "Profit",
        overview: "Vue d'ensemble",
        from: "de",
        secondTo: "à",

        January: "Janvier",
        February: "Février",
        March: "Mars",
        April: "Avril",
        May: "Mai",
        June: "Juin",
        July: "Juillet",
        August: "Août",
        September: "Septembre",
        October: "Octobre",
        November: "Novembre",
        December: "Décembre",

        clientTel: "Numéro de téléphone du client",
        clientEmail: "E-mail du client",
        header: "Entête",
        footer: "Bas de page",
        default: "Défaut",
        userDefined: "Défini par l'utilisateur",
        hour: "Heure",
        actions: "Actions",
        bulkActions: "Actions en vrac",
        spent: "Dépensé",
        deleteAssociated: "Les documents associés seront supprimés.",
        newDocument: "Nouveau document",
        invoice: "Facture",
        paid: "Payé",
        unpaid: "Impayé",
        payment: "Payement",
        proforma: "Facture proforma",
        creditNote: "Note de crédit",
        creditNotes: "Notes de crédit",
        reminder: "Rappel",
        number: "Numéro",
        date: "Date",
        amount: "Montant",
        elapsedTime: "Temps écoulé",
        days: "Journées",
        notice: "Mise en demeure",
        page: "Page",
        preview: "Aperçu",
        previewSend: "Prévisualiser et envoyer",
        update: "Actualiser",
        attestation: "Attestations",
        totalNoVat: "Total hors TVA",
        totalWithVat: "Total avec TVA",
        restToPay: "Montant dû",
        gift: "Cadeau",
        orderTicket: "Bon de commande",
        notsaved: "Pas enregistré",
        saved: "Enregistré",
        selectImages: "Sélectionner les images",
        nothingSelected: "Rien ne est sélectionné",
        deleteItem: "Voulez-vous supprimer l'élément?",
        original: "Original",
        add: "Rajouter",
        modify: "Modifier",
        name: "Nom",
        quantity: "Quantité",
        unitPrice: "Prix unitaire",
        options: "Options",
        description: "Description",
        materialsCost: "Coût des matériaux",
        laborCost: "Main-d'œuvre",
        laborTime: "Temps de travail (h)",
        articles: "Articles",
        article: "Article",
        workloadSubdivision: "Subdivisions du travail",
        totalMerchandisesNoVat: "Coût total",
        totalPayment: "Total à payer",
        leftToPay: "Reste à payer",
        vat: "TVA",
        documents: "Documents",
        lastDocuments: "Derniers documents",
        estimates: "Devis",
        orderTickets: "Bons de commande",
        invoices: "Factures",
        received: "Reçu le",
        suplementSheet: "Fiche de supplément",
        companyContract: "Contrat d'entreprise",
        vatAttestation: "Attestations TVA",
        pvTemporarReception: "PV réception provisoire",
        pvDefinitiveReception: "PV réception définitive",
        heatingWorkTicket: "Bon de travaux chauffage",
        orderTicketSubcontractors: "Bon de commande Sous-Traitance",
        subcontractorContract: "Contrat sous-traitance",
        generalConditions: "Conditions générales",

        receipts: "Reçus",
        proformas: "Proformas",
        newProforma: "Nouvelle facture proforma",

        disabled: "Inactif",
        disable: "Désactiver",
        remove: "Supprimer",
        delete: "Effacer",
        select: "Sélectionner",
        save: "Enregistrer",
        contracts: "Contrats",
        extraWorkReports: "Raport travail extra",
        extraCostsReports: "Raport couts extra",
        attachedFiles: "Fichiers attachés",
        attestationDocuments: "Attestations",
        clients: "Clients",
        documentTemplates: "Echantillon du document",
        companyUsers: "Utilisateurs de l'entreprise",
        addNewUser: "Ajouter un nouvel utilisateur",
        basicInfo: "Infos de base",
        details: "Détails",
        payments: "Paiements",
        onlinePayments: "Paiements en ligne",
        extraCosts: "Couts extra",
        other: "Autres",
        vatRate: "Type TVA",
        client: "Client",
        selectAValue: "Choisir une option",
        addSelected: "Ajouter sélectionné",
        edit: "Modifier",
        extra: "Extra",
        service: "Services",
        currency: "Monnaie",
        status: "Statut",
        observations: "Observations",
        firstName: "Prénom",
        email: "Email",
        webPage: "Page Web",
        postalCode: "Code postal",
        logo: "Logo",
        address: "Adresse",
        city: "Ville",
        country: "Pays",
        code: "Code",
        company: "Entreprise",
        image: "Image",

        // folders
        folder: "Projet",
        folders: "Projets",
        folderNo: "Nr. Projet",
        clientName: "Nom client",
        clientAddress: "Adresse client",
        startDate: "Date début",
        endDate: "Date fin",
        folderOptions: "Options projet",
        newFolder: "Nouveau projet",
        modifyFolder: "Modifier projet",
        constructionType: "Type Construction",
        dateOfPayment: "Date paiement",
        addPayment: "Rajout à payer",
        addExtraCost: "Rajout extra cout",
        paymentNote: "Note du paiement",
        finalPaymentNote: "Note finale",
        title: "Titre",
        owner: "Propriétaire",

        // document Templates
        estimatesTemplate: "Modéle devis",
        orderTicketTemplate: "Modéle bon de comande",
        invoiceTemplate: "Modéle facture",
        receiptTemplate: "Modéle reçu",
        contractTemplate: "Modéle contrat",
        extraWorkReportTemplate: "Modéle rapport extra travaux",
        extraCostsReportTemplate: "Modéle rapport extra couts",
        attestationTemplate: "Modéle attestation",
        template: "Modéle",

        // documents
        lastInvoices: "Dernières factures",
        lastEstimates: "Derniers devis",
        lastorderTickets: "Derniers bons de comande",
        lastorderDocuments: "Derniers documents",

        // receipts
        newReceipt: "Nouveau reçu",
        receipt: "Reçu",

        // order tickets
        newOrderTicket: "Nouveau bon de commande",

        // invoices
        newInvoice: "Nouvelle facture",
        invoicesOptions: "Options de facture",

        // reports
        newReport: "Nouveau rapport",

        //estimates
        estimate: "Devis",
        newEstimate: "Nouveau devis",
        estimateNo: "Numéro de devis",
        estimatesOptions: "Options de devis",

        // contracts
        newContract: "Nouveau contrat",

        // attestation documents
        newAttestationDoc: "Nouveau document d'attestation",

        // companies
        companies: "Entreprise",
        newCompany: "Nouvelle entreprise",
        companyInfo: "Info entreprise",
        vatNo: "Numéro TVA",
        vatType: "Type TVA",
        companyRegNo: "Numero d'enregistrement d'entreprise",
        bankBic: "Code bancaire BIC",
        bankName: "Nom de banque",
        bankAccount: "Compte bancaire",
        online: "Online",
        bankTransfer: "Virement bancaire",
        account: "Compte",
        telFax: "Téléphone/Fax",
        mobile: "Mobile",
        tel: "Téléphone",
        fax: "Fax",
        secondary: "Secondaire",

        // clients
        newClient: "Nouveaux clients",
        clientDiscount: "Remise client",
        contactPerson: "Personne de contact",
        clientInfo: "Informations client",
        contact: "Contact",

        // categories
        category: "Categorie",
        categories: "Categories",
        subcategory: "Sous-catégorie",

        // services
        services: "Services",
        measureUnit: "Unité de mesure",
        price: "Prix",
        cost: "Coût",
        tradeMargin: "Marge commerciale (%)",
        newService: "Nouveau service",
        addServiceArticles: "Ajouter des articles de service",

        // articles
        brand: "Marque",
        diameter: "Diamètre",
        newArticle: "Nouvel article",
        coefficient: "Coefficient",
        updateFromFile: "Mise à jour du fichier",
        updateFromWebpage: "Mise à jour du site",
        productsFile: "Fichier de produit",
        imagesFile: "Fichier des images",
        articleGroup: "Groupe d'articles",

        subcontractors: "Sous-traitants",
        subcontractors_employees: "Employés des sous-traitants",
        company_employees: "Employés de l'entreprise",
        newSubcontractor: "Nouveau sous-traitant",
        subcontractor: "Sous-traitant",
        subcontractorAddress: "Adresse sous-traitant",
        parent: "Parent",
        totalPrice: "Prix total",
        totalHours: "Nombre total d'heures",
        siteAddress: "Adresse du site",
        workSite: "Site",
        sameAsClient: "Identique à l'adresse du client",
        emptyLine: "Ligne vide",
        value: "Valeur",
        content: "Contenu",
        paymentDue: "Dèlai de paiement",
        paymentMode: "Mode de paiement",
        paymentMethod: "Mode de paiement",
        bank: "Banque",
        cash: "Especes",
        downPayment: "Acompte",
        discount: "Remise",
        amountNoVat: "Montant hors TVA",
        amountWithVat: "Montant TTC",
        message: "Message",
        attachment: "Attachement",
        emailSignature: "Signature email",
        subject: "Sujet",
        to: "Pour",
        generateInvoice: "Générer la facture",
        active: "Actif",
        inactive: "Inactif",
        all: "Tous",
        year: "Annèe",
        addYear: "Ajouter une année",
        addNewBudgetEstimate: "Ajouter un nouveau budget",
        filter: "Filtre",
        total: "Total",
        generate: "Générer",
        timeElapsed: "Temps ècoulè",
        start: "Début",
        middle: "Milieu",
        end: "Fin",
        work: "Travail",
        signature: "Signature",
        reminderEmailSignature: "Signature e-mail rappel",
        estimateEmailSignature: "Signature e-mail devis",
        orderTicketEmailSignature: "Signature e-mail bon de commande",
        startWork: "Début des travaux",
        duringWork: "Pendant les travaux",
        endWork: "Fin des travaux",
        gallery: "Galerie",
        expense: "Dépense",
        upload: "Télécharger",
        send: "Expédier",
        new: "Nouveau",
        reset: "Réinitialiser",
        reimburse: "à rembourser",
        subcontractorOrderTickets: "Sous-traitants / Employés bons de commande",
        subcontractorOrderTicket: "Sous-traitant / Employé bon de commande",
        employeesOrderTickets: "Employés bons de commande",
        single: "Singulier",
        copy: "Copier",
        paste: "Coller",
        accounting: "Comptabilité",
        existing: "Existant",
        none: "Aucun",
        contract: "Contrat",
        employee: "Employé",
        employees: "Employés",
        lastLocationToday: "Dernier lieu aujourd'hui",
        locationAlert: "Alerte emplacement",
        outOfRange: "Hors de portée",
        search: "Recherche",
        alerts: "Alertes",
        alertInfo: "Informations d'alerte",
        reports: "Rapports",
        clocking: "Horologe",
        login: "Connexion",
        logout: "Déconnexion",
        username: "Utilisateur",
        password: "Mot de passe",
        newPassword: "Nouveau mot de passe",
        repeatNewPassword: "Répété le nouveau mot de passe",
        repeatPassword: "Répéter le mot de passe",
        role: "Fonction",
        rememberMe: "Se rappeler de moi",
        forgotPassword: "J'ai oublié mon mot de passe",
        passwordsDontMatch: "Les mots de passe ne correspondent pas.",
        usernameExists: "Ce nom d'utilisateur existe déjà.",

        //errors
        compulsoryUserPassword:
          "Le nom d'utilisateur et le mot de passe sont requis.",
        wrongUserOrPassword:
          "Le nom d'utilisateur ou le mot de passe est éroné.",

        // client errors
        clientExists: "Ce client existe déjà.",
        clientDoesNotExist: "Ce client n'existe pas.",

        // category errors
        categoryExists: "Cette catégorie existe déjà.",
        categoryStillUsed:
          "La catégorie est utilisée par les services. Supprimez d'abord les services qui y sont attachés.",
        deleteCategory: "Supprimer la catégorie et ses services?",

        // services
        ordersWithServiceExist:
          "Il existe des projets contenant ce service. Supprimez d'abord les projets en question.",
        serviceDoesNotExists: "Le service n'existe pas.",
        summaryEstimates: "Résumé des estimations",
        // estimates
        estimateWithSameNumberExists:
          "Un devis avec le meme numéro existe déjà.",
        estimateDoesNotExists: "Le devis n'existe pas.",

        invoiceWithSameNumberExists:
          "Une facture avec le meme numéro existe déjà.",
        sameNumberExists: "Ce numéro existe déjà.",

        // general errors
        notFound: "Introuvable",
        error: "Erreur",
        selectError: "Veuillez sélectionner un élément de la liste.",
        saveBaseService: "Enregistrer et remplacer le service de base?",
        noInitialServiceFound: "Aucun service initial trouvé.",
        move: "Bouger",
        moving: "En mouvement",
        summary: "Récapitulatif",
        locate: "Localiser",
        useLocation: "Utiliser l'emplacement",
        blocked: "Bloqué",
        unblocked: "Débloqué",
        hidePrices: "Masquer les prix",
        totalToInvoice: "Total à facturer",
        amountLeftToInvoice: "Montant pour cette facture",
        totalAuthorizedToInvoice: "Total autorisé à facturer",
        alreadyInvoiced: "Déja facturé précédemment",
        creditCard: "Carte de crédit/débit",
        dragFiles: "Faites glisser les fichiers ici",
        open: "Ouvert",
        closed: "Fermé",
        liability: "Dettes",
        minValue: "Valeur minimale",
        maxValue: "Valeur maximale",
        docType: "Type de document",
        paymentTerm: "Echéance",
        notifications: "Notifications",
        notification: "Notification",
        supplier: "Fournisseur",
        equivalent: "Équivalent",
        alreadyPaid: "Déjà payée(s)",
        costs: "Frais",
        presenceOfContractor:
          "En présence de l’entrepreneur (ou de son représentant)",
        presenceOfDeveloper: "En présence du maître d’œuvre",
        absenceOfContractor:
          "En l’absence de l’entrepreneur, dûment convoqué par lettre recommandée avec avis de réception",
        contractor: "Entrepreneur",
        developer: "Maître d’ouvrage",
        assistant: "Assistant",
        warranty: "Garantie",
        actualCosts: "Coûts réels",
        estimatedCosts: "Coûts estimés",
        omniumWarranty:
          "L’omnium - Elle couvre tout : le déplacement, le salaire horaire et la pièce détachée à remplacer.",
        regularWarranty:
          "La garantie - Seule la pièce défectueuse sera remplacée gratuitement. Le salaire horaire et le déplacement doivent être payés.",
        digressiveWarranty:
          "La garantie dégressive - Comme la garantie, à la différence qu’après un certain nombre d’années, il faut payer un certain pourcentage de la pièce défectueuse.",
        unauthorized: "L'accès n'est pas autorisé",
        addText: "Ajouter du texte",
        groupName: "Nom de groupe",
        duplicate: "Dupliquer",
        optional: "Optionnel",
        invoicing: "Facturation",
        costManagement: "Gestion des coûts",
        representativeName: "Nom représentant",
        generalInfo: "Informations générales",
        bankInfo: "Informations bancaires",
        admin: "Administrateur",
        timekeeping: "Fiche de pointage",
        map: "Carte",
        clientType: "Type de client",
        tenant: "Locataire",
        usufructuary: "Usufruitier",
        manager: "Gestionnaire",
        packages: "Paquets",
        package: "Paquet",
        docNo: "Numéro du document",
        companyName: "Nom de l'entreprise",
        companyAddress: "Adresse de l'entreprise",
        paymentDate: "Echéance",
        clientNo: "Code client",
        clientVatNo: "Numéro TVA client",
        up: "En haut",
        upgradeForMoreClients:
          "Modifier le plan d'abonnement pour plus de clients.",
        reconnect:
          "Une erreur s'est produite lors de la récupération des données. Veuillez vous reconnecter.",
      },
      en: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        invalidDate: "Invalid date",
        home: "Home",
        showMore: "Show more",
        showLess: "Show less",
        estimatedTotal: "Estimated total",
        actualTotal: "Actual total",
        offer: "Estimate",
        months: "Months",
        navigation: "Navigation",
        noActivity: "No activity detected. You will be logged out shortly.",
        noEmployees: "No employees available.",
        hr: "Human resources",
        additionalInformation: "Additional information",
        emailNotConfirmed: "The email address has not yet been confirmed.",
        pleaseConfirmEmail:
          "A confirmation email was sent to the provided address.",
        confirmEmail: "Please confirm your email to continue.",
        confirmedEmail: "Your email was confirmed.",
        resendEmail: "Resend email",
        receiveCC: "Add in cc",
        noDisposableEmail: "Disposable e-mails are not allowed.",
        vatValidationErr: "VAT number validation has failed.",
        export: "Export",
        size: "Size",
        section: "Section",
        container: "Container",
        embed: "Embed",
        ascending: "Ascending",
        descending: "Descending",
        sort: "Sort",
        language: "en-gb",
        reference: "Reference",
        loading: "Loading",
        warning: "Warning",
        alreadySelected: "Already selected",
        requiredColumns: "Required columns",
        report: "Report",
        userRoles: "User roles",
        atLeast13years: "You should have at least 13 years old to use Stripe.",
        onlyOne: "only one",
        companyOverview: "Company overview",
        companyDetails: "Please fill in your company's details.",
        additionalData: "Additional data",
        noPayments: "No payments",
        noPayouts: "No payouts",
        generatePdf: "Generate PDF",
        pleaseSelectServicesAndPackage:
          "Please select the services and the package.",
        moveServicesInNewCategory: "Move services in another category.",
        changeAllServicePrices: "Change all service prices.",
        addServicesInselectedPackage: "Add the services in selected package.",
        saveMessageAsTemplate: "Save the message as template for future use.",
        addressNotFound: "Address not found",
        takeAPhoto: "Take a photo",
        workInConstruction: "Do you work in construction?",
        type: "Type",
        administrative: "Administrative cost",
        purchase: "Purchase",
        workforce: "Workforce",
        sumInCash: "Sum received cash",
        create: "Create",
        close: "Close",
        selectServices: "Select your services",
        pleaseselectServices: "Please select the services.",
        insertCompanyInfo: "Please insert all company info.",
        insertSupplier: "Please insert a supplier.",
        selectClient: "Please select a client.",
        budgetEstimate: "Budget estimate",
        invalid: "invalid",
        valid: "valid",
        confirmContract: "You need to add a template for the contract.",
        tryAgain: "Try again",
        color: "Color",
        curency: "Curency",
        reminderNotificationMinAmount: "Minimum value for notification",
        reminderDelay: "Reminder delay",
        notificationDelay: "Notification delay",
        noNewNotifications: "No new notifications",
        continuousInvoiceNumbers: "Invoice numbers are reset each year to 1",
        generatedWith: "Generated with",
        currentPassword: "Current password",
        pleaseSelect: "Please select: ",
        selectParentCategory: "Select parent category",
        signBelow: "Sign below",
        saveSignature: "Save signature",
        erase: "Erase",
        pressToSign: "Press here to sign",
        termsAndConditions:
          "I have read and I accept the terms and conditions.",
        confirm: "Confirm",
        accept: "Accept",
        minOneLetterAndNumber:
          "The password must contain at least one letter and one number.",
        min8Character: "The password must contain at least 8 characters.",
        emailNotValid: "The email entered is not valid.",
        fieldRequired: "This field is required.",
        passwordMismatch: "The passwords entered do not match.",
        cancel: "Cancel",
        disableAccount: "Are you sure you want to disable this account?",
        saveService: "Are you sure you want to save this service?",
        yes: "Yes",
        no: "No",
        register: "Register",
        back: "Back",
        design: "Design",
        about: "About",
        workPerHour: "Work pe hour",
        perDay: "Per day",
        perHour: "Per hour",
        inSelectedInterval: "In selected interval",
        toLargeOrWrongFormat: "The file is too large or the wrong format.",
        badpayee: "Defaulter",
        success: "Success",
        successfullyCreated:
          "Your Stripe account has been successfully created.",
        successfullyDisabled: "Your Stripe account has been deactivated.",
        modifyPrices: "Modify prices",
        pricePerHour: "Price per hour",
        trimester: "Trimester",
        withBreak: "Minus break",
        addAnnualCost: "Add annual cost",
        dailyBreak: "Daily break",
        minutes: "minutes",
        stop: "Stop",
        available: "Available",
        pending: "Pending",
        balance: "Balance",
        industry: "Industry",
        verifyIdentityDocument: "Verify identity document",
        verifyHomeAddress: "Verify home address",
        payouts: "Payouts",
        personalIdNumber: "Personal id number",
        familyName: "Family name",
        dateOfBirth: "Date of birth",
        bancontact: "Bancontact",
        modifyPricesByPercent: "Modify prices by percentage.",
        enable: "Enable",
        enableOnlinePayments: "Enable online payments",
        vatNoLength: 10,
        serviceName: "Please enter the service name.",
        minAmount: "Minimum amount",
        series: "Series",
        monthly: "Monthly",
        ok: "OK",
        upgrade: "Upgrade",
        subscription: "Subscription",
        rename: "Rename",
        import: "Import",
        getSubscription: "Get a subscription",
        upgradeSubscription: "Upgrade subscription",
        doc: "Document",
        day: "day",
        trial: "Trial period",
        finish: "Finish",
        linkCopied: "Link copied",
        next: "Next",
        tutWelcomeTitle: "Welcome!",
        tutWelcomeContent:
          "This is a very brief overview of the interface to be productive in the shortest possible time.",
        tutLeftMenuTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> The main menu.",
        tutLeftMenuContent:
          "In the main menu you will find all the documents created. The reports can provide different types of analyses, as well as detailed information about the company.",
        tutNewDocumentTitle: "Create a new document or project.",
        tutNewDocumentContent:
          "Wherever you are in the application, here you can create a new document or project.  <span class='glyphicon glyphicon-chevron-right'></span>",
        tutNotoficationsTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Notifications.",
        tutNotoficationsContent:
          "Here you can see the notifications generated by the application.",
        tutOptionsTitle:
          "Options and logout. <span class='glyphicon glyphicon-chevron-left'></span>",
        tutOptionsContent:
          "On the right side of the interface, you can access the options and you can close the application.",
        tutDocumentInfoTitle: "New Document.",
        tutDocumentInfoContent:
          "The document has two parts, one for general information and one for services and products. The basic information is at the top of the interface. To save the document click on the Save button.",
        tutDocumentDetailsTitle: "Add products and services.",
        tutDocumentDetailsContent:
          "Here you can add services and products by clicking on the + button at the top or by adding the first product from the menu on the right. They will automatically save the previously created document.",

        foldersTutBasicInfo:
          "Here you change the information in the customer file and the location where the services are delivered.",
        foldersTutInvoices: "Income. Proforma invoices and credit notes.",
        foldersTutInvoicesDes:
          "Create a new document based on the selected tab. Press the + (plus) button to create a new document in the active folder. These will also be visible in the list of all documents (proforma invoices ...). If you have added a wrong invoice to the file, it can be disassociated by selecting it and pressing the - (minus) button. At the bottom, you have summary information about the documents in the selected spreadsheet.",
        foldersTutOrderTickets:
          "Order receipts and documents for subcontractors.",
        foldersTutCostsDes:
          "Add costs related to the project to monitor the financial situation of the case.",
        foldersFileExplorerDes:
          "Project documents sent by email will also be saved in PDF format here. You can add service-related records to get all the information in one place and avoid problems of customer confusion.",

        docTutBasicInfo: "Edit the document and customer information here.",
        docTutWorkloadSubdivision:
          "To create documents, you can create subdivisions that will fit into the services provided.",
        docTutCategories:
          "Within these subdivisions, services are divided into categories and subcategories. The services you create will be placed in the chosen category and subdivision. If these are not chosen, the services are added to the default category and subdivision.",
        docTutAddDeleteService:
          "The + (plus) button adds a new service to your list of services. The button next to it (bin) removes the selected services from the document.",
        docTutCategoryDescription:
          "The categories in the document may have a broader description. Here they can be written, and saved by pressing the right button.",
        docTutService:
          "The service is initially empty. By filling in the name, you can select from the list of previously saved service suggestions. You create a new one if you do not select anything. The services are saved automatically when you move to the next field.",
        docTutServiceDetails:
          "The new services can be modified in detail by clicking on the options button ... (three dots). After modification, it is automatically saved in the document, but it can be updated in the service database by the Save button, top right.",
        docTutTotals:
          "At the bottom, the totals are automatically updated with the added services.",
        docTutDocumentPreview:
          "The preview button generates the document in printable format. It can be previewed, printed, emailed to the client or annotated.",
        docTutBlockUnblock:
          "The lock/unlock buttons can lock all fields in the document so that they cannot be changed.",
        docTutOtherOptions:
          "Depending on the document in the menu, other specific options appear for each type of document.",

        createRecurrent:
          "The invoice is generated automatically at the selected interval of days. Do you want to continue?",
        recurrenceInterval: "Recurrence interval (Days)",
        recurrentInvoice: "Recurrent invoice",
        paymentDeadline: "Payment deadline",
        withoutVat: "Without VAT",
        notUsed: "Not used",
        dashboard: "Dashboard",
        choosePlan: "Choose plan",
        chooseMonth: "Choose month",
        sepaTerms:
          "By signing this mandate form, you authorize Cronoptimal to send instructions to your bank to debit your account, and your bank to debit your account in accordance with the instructions of Cronoptimal. You have a right to reimbursement by your bank under the conditions described in the agreement you have spent with her. All refund requests must be submitted within 8 weeks of the date your account was debited. Please complete the fields marked *.",
        pay: "Pay",
        youChoose: "You have chosen the",
        profit: "Profit",
        overview: "Overview",
        from: "from",
        secondTo: "to",

        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",

        clientTel: "Client phone number",
        clientEmail: "Client Email",
        header: "Header",
        footer: "Footer",
        default: "Default",
        userDefined: "User defined",
        hour: "Hour",
        actions: "Actions",
        bulkActions: "Bulk actions",
        spent: "Spent",
        deleteAssociated: "Associated documents will be deleted.",
        newDocument: "New document",
        invoice: "Invoice",
        paid: "Paid",
        unpaid: "Unpaid",
        payment: "Payment",
        proforma: "Pro-forma",
        creditNote: "Credit note",
        creditNotes: "Credit notes",
        reminder: "Reminder",
        number: "Number",
        date: "Date",
        amount: "Amount",
        elapsedTime: "Elapsed time",
        days: "Days",
        notice: "Notification",
        page: "Page",
        preview: "Preview",
        previewSend: "Preview and send",
        update: "Update",
        attestation: "Attestation",
        totalNoVat: "Total without VAT",
        totalWithVat: "Total with VAT",
        restToPay: "Amount due",
        gift: "Gift",
        orderTicket: "Order ticket",
        notsaved: "Not saved",
        saved: "Saved",
        selectImages: "Select the images",
        nothingSelected: "Nothing selected",
        deleteItem: "Do you want to delete the item?",
        original: "Original",
        add: "Add",
        modify: "Modify",
        name: "Name",
        quantity: "Quantity",
        options: "Options",
        unitPrice: "Unit price",
        description: "Description",
        materialsCost: "Materials cost",
        laborCost: "Labor cost",
        laborTime: "Labor time",
        articles: "Articles",
        article: "Article",
        workloadSubdivision: "Work load subdivision",
        totalMerchandisesNoVat: "Total Cost",
        totalPayment: "Total payment",
        leftToPay: "Left to pay",
        vat: "Vat",
        disabled: "Disabled",
        disable: "Disable",
        remove: "Remove",
        delete: "Delete",
        select: "Select",
        save: "Save",
        documents: "Documents",
        lastDocuments: "Last documents",
        estimates: "Estimates",
        orderTickets: "Order Tickets",
        invoices: "Invoices",
        received: "Received",
        suplementSheet: "Suplement sheet",
        companyContract: "Company contract",
        vatAttestation: "VAT Attestation",
        pvTemporarReception: "PV temporar reception",
        pvDefinitiveReception: "PV definitive reception",

        heatingWorkTicket: "Heating work ticket",
        orderTicketSubcontractors: "Order ticket subcontractors",
        subcontractorContract: "Subcontractors contract",
        generalConditions: "General conditions",

        receipts: "Receipts",
        proformas: "Pro-formas",
        newProforma: "New pro-forma invoice",
        contracts: "Contracts",
        extraWorkReports: "Extra work Report",
        extraCostsReports: "Extra Costs Report",
        attachedFiles: "Attached Files",
        attestationDocuments: "Attestation docs",
        clients: "Clients",
        documentTemplates: "Documents Templates",
        companyUsers: "Company users",
        addNewUser: "Add new user",
        basicInfo: "Basic Info",
        details: "Details",
        payments: "Payments",
        onlinePayments: "Online Payments",
        extraCosts: "Extra Costs",
        other: "Other",
        vatRate: "VAT Rate",
        client: "Client",
        selectAValue: "Select a value",
        addSelected: "Add Selected",
        edit: "Edit",
        extra: "Extra",
        service: "Service",
        currency: "Currency",
        status: "Status",
        observations: "Observations",
        firstName: "First Name",
        email: "Email",
        webPage: "Web Page",
        postalCode: "Postal code",
        logo: "Logo",
        address: "Address",
        city: "City",
        country: "Country",
        code: "Code",
        company: "Company",
        image: "Image",

        // folders
        folder: "Project",
        folders: "Projects",
        folderNo: "Project number",
        clientAddress: "Client address",
        startDate: "Start date",
        endDate: "End date",
        folderOptions: "Project options",
        newFolder: "New project",
        modifyFolder: "Modify project",
        constructionType: "Construction type",
        dateOfPayment: "Payment date",
        addPayment: "Add payment",
        addExtraCost: "Add extra cost",
        paymentNote: "Payment note",
        finalPaymentNote: "Final note",
        title: "Title",
        owner: "Owner",

        // document Templates
        estimatesTemplate: "Estimates template",
        orderTicketTemplate: "Order ticket template",
        invoiceTemplate: "Invoice template",
        receiptTemplate: "Receipt template",
        contractTemplate: "Contract template",
        extraWorkReportTemplate: "Extra work report template",
        extraCostsReportTemplate: "Extra costs report template",
        attestationTemplate: "Attestation template",
        template: "Template",

        // documents
        lastInvoices: "Last invoices",
        lastEstimates: "Last estimates",
        lastOrderTickets: "Last order tickets",
        lastOrderDocuments: "Last order documents",

        // receipts
        newReceipt: "New receipt",
        receipt: "Receipt",

        // order tickets
        newOrderTicket: "New order ticket",

        // invoices
        newInvoice: "New invoice",
        invoicesOptions: "Invoices options",

        // reports
        newReport: "New report",

        //estimates
        estimate: "Estimate",
        newEstimate: "New Estimate",
        estimateNo: "Estimate number",
        estimatesOptions: "Estimates options",

        // contracts
        newContract: "New contract",

        // attestation documents
        newAttestationDoc: "New attestation document",

        // companies
        companies: "Companies",
        newCompany: "New Company",
        companyInfo: "Company Info",
        vatNo: "VAT Number",
        vatType: "VAT Type",
        companyRegNo: "Company Reg Number",
        bankBic: "Bank BIC Code",
        bankName: "Bank Name",
        bankAccount: "Bank Account",
        online: "Online",
        bankTransfer: "Bank transfer",
        account: "Account",
        telFax: "Telephone/Fax",
        mobile: "Mobile",
        tel: "Telephone",
        fax: "Fax",
        secondary: "Secondary",

        // clients
        newClient: "New client",
        clientName: "Client name",
        clientDiscount: "Client discount",
        contactPerson: "Contact person",
        clientInfo: "Client information",
        contact: "Contact",

        // categories
        category: "Category",
        categories: "Categories",
        subcategory: "Subcategory",

        // services
        services: "Services",
        measureUnit: "Measure unit",
        price: "Price",
        cost: "Cost",
        tradeMargin: "Trade margin (%)",
        newService: "New service",
        addServiceArticles: "Add service articles",

        // articles
        brand: "Brand",
        diameter: "Diameter",
        newArticle: "New article",
        coefficient: "Coefficient",
        updateFromFile: "Update from file",
        updateFromWebpage: "Update from webpage",
        productsFile: "Products file",
        imagesFile: "Images file",
        articleGroup: "Article Group",

        subcontractors: "Subcontractors",
        subcontractors_employees: "Subcontractors employees",
        company_employees: "Company employees",
        newSubcontractor: "New subcontractor",
        subcontractor: "Subcontractor",
        subcontractorAddress: "Subcontractor address",
        parent: "Parent",
        totalPrice: "Total price",
        totalHours: "Total number of hours",
        siteAddress: "Site address",
        workSite: "Work site",
        sameAsClient: "Same as client",
        emptyLine: "Empty line",
        value: "Value",
        content: "Content",
        paymentDue: "Payment due",
        paymentMode: "Payment mode",
        paymentMethod: "Payment mode",
        bank: "Bank",
        cash: "Cash",
        downPayment: "Down payment",
        discount: "Discount",
        amountNoVat: "Amount without Vat",
        amountWithVat: "Amount with Vat",
        message: "Message",
        attachment: "Attachment",
        emailSignature: "Email signature",
        subject: "Subject",
        to: "To",
        generateInvoice: "Generate invoice",
        active: "Active",
        inactive: "Inactive",
        all: "All",
        year: "Year",
        addYear: "Add year",
        addNewBudgetEstimate: "Add new budget estimate",
        filter: "Filter",
        total: "Total",
        generate: "Generate",
        timeElapsed: "Time elaplsed",
        start: "Start",
        middle: "Middle",
        end: "End",
        work: "Work",
        signature: "Signature",
        reminderEmailSignature: "Email reminder signature",
        estimateEmailSignature: "Email estimate signature",
        orderTicketEmailSignature: "Email order ticket signature",
        startWork: "Work start",
        duringWork: "During work",
        endWork: "End work",
        gallery: "Gallery",
        expense: "Expense",
        upload: "Upload",
        send: "Send",
        new: "New",
        reset: "Reset",
        reimburse: "To reimburse",
        subcontractorOrderTickets: "Subcontractors / Employees order tickets",
        employeesOrderTickets: "Employees order tickets",
        subcontractorOrderTicket: "Subcontractor / Employee order ticket",
        single: "Single",
        copy: "Copy",
        paste: "Paste",
        accounting: "Accounting",
        existing: "Existing",
        none: "None",
        contract: "Contract",
        employee: "Employee",
        employees: "Employees",
        activeWorkers: "Active workers",
        lastLocationToday: "Last location today",
        locationAlert: "Location alert",
        outOfRange: "Out of range",
        search: "Search",
        alerts: "Alerts",
        alertInfo: "Alert information",
        reports: "Reports",
        clocking: "Clocking",
        login: "Log in",
        logout: "Sign out",
        username: "User",
        password: "Password",
        newPassword: "New password",
        repeatNewPassword: "Repeat the new password",
        repeatPassword: "Repeat password",
        role: "Role",
        rememberMe: "Remember me",
        forgotPassword: "Forgot your password?",
        passwordsDontMatch: "Passwords don't match.",
        usernameExists: "This user name already exists.",

        //errors
        compulsoryUserPassword: "User name and password are required.",
        wrongUserOrPassword: "The username or password is wrong.",

        // client errors
        clientExists: "This client already exists.",
        clientDoesNotExist: "This client does not exist.",

        // category errors
        categoryExists: "This category already exists.",
        categoryStillUsed:
          "The category is used by services. First delete child services.",
        deleteCategory: "Delete category and it's services?",

        // services
        ordersWithServiceExist:
          "Projects that contain this service exist. First delete dependent projects.",
        serviceDoesNotExists: "Service does not exist.",
        summaryEstimates: "Summary of estimates",
        // estimates
        estimateWithSameNumberExists:
          "An estimate with the same number already exists.",
        estimateDoesNotExists: "Estimate does not exist.",

        invoiceWithSameNumberExists:
          "An invoice with the same number already exists.",
        sameNumberExists: "The number already exists.",

        // general errors
        notFound: "Not Found",
        error: "Error",
        selectError: "Please select an item from the list.",
        saveBaseService: "Register and replace the basic service?",
        noInitialServiceFound: "No initial service found.",
        move: "Move",
        moving: "Moving",
        summary: "Summary",
        locate: "Locate",
        useLocation: "Use location",
        blocked: "Blocked",
        unblocked: "Unblocked",
        hidePrices: "Hide prices",
        totalToInvoice: "Total to invoice",
        amountLeftToInvoice: "Amount left to invoice",
        totalAuthorizedToInvoice: "Total authorized to invoice",
        alreadyInvoiced: "Already invoiced",
        creditCard: "Credit/Debit card",
        dragFiles: "Drag files here",
        open: "Open",
        closed: "Closed",
        liability: "Liability",
        minValue: "Minimum value",
        maxValue: "Maximum value",
        docType: "Document type",
        paymentTerm: "Payment Term",
        notifications: "Notifications",
        notification: "Notification",
        supplier: "Supplier",
        equivalent: "Equivalent",
        alreadyPaid: "Already paid",
        costs: "Costs",
        estimatedCosts: "Estimated costs",
        presenceOfContractor:
          "In the presence of the contractor (or his representative)",
        presenceOfDeveloper: "In the presence of the project manager",
        absenceOfContractor:
          "In the absence of the contractor, duly convened by registered letter with acknowledgement of receipt",
        contractor: "Contractor",
        developer: "Project owner",
        assistant: "Assistant",
        warranty: "Warranty",
        actualCosts: "Actual costs",
        omniumWarranty:
          "The Omnium - It covers all: transportation, hourly wages and spare parts to replace",
        regularWarranty:
          "Warranty - Only the defective part will be replaced free of charge. Hourly wages and transportation must be paid",
        digressiveWarranty:
          "The Digressive guarantee - As the regular warranty, except that after a number of years you have to pay a certain percentage of the defective part.",
        unauthorized: "Access is not allowed",
        addText: "Add text",
        groupName: "Group name",
        duplicate: "Duplicate",
        optional: "Optional",
        invoicing: "Invoicing",
        costManagement: "Cost Management",
        representativeName: "Representative name",
        generalInfo: "General information",
        bankInfo: "Bank information",
        admin: "Administrator",
        timekeeping: "Time tracking",
        map: "Map",
        clientType: "Client type",
        tenant: "Tenant",
        usufructuary: "Usufructuary",
        manager: "Manager",
        packages: "Packages",
        package: "Package",
        docNo: "Document number",
        companyName: "Company name",
        companyAddress: "Company address",
        paymentDate: "Payment date",
        clientNo: "Client code",
        clientVatNo: "Client VAT No",
        up: "Up",
        upgradeForMoreClients:
          "Change the subscription plan for more customers.",
        reconnect:
          "An error occurred while retrieving the data. Please log in again.",
      },
      nl: {
        january: "Januari",
        february: "Februari",
        march: "Maart",
        april: "April",
        may: "Kunnen",
        june: "Juni",
        july: "Juli",
        august: "Augustus",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "December",
        home: "Huis",
        invalidDate: "Ongeldige datum",
        showMore: "Laat meer zien",
        showLess: "Laat minder zien",
        estimatedTotal: "Geschat totaal",
        actualTotal: "Werkelijk totaal",
        offer: "Aanbod",
        months: "Maanden",
        navigation: "Navigatie",
        noActivity: "Geen activiteit waargenomen. U wordt zo uitgelogd.",
        noEmployees: "Geen medewerkers beschikbaar.",
        hr: "Personeelszaken",
        additionalInformation: "Meer informatie",
        emailNotConfirmed: "Het e-mailadres is nog niet bevestigd.",
        pleaseConfirmEmail:
          "Een bevestigingsmail werd naar het opgegeven adres gestuurd.",
        confirmEmail: "Gelieve uw e-mail te bevestigen om verder te gaan.",
        confirmedEmail: "Uw e-mail werd bevestigd.",
        resendEmail: "Email opnieuw verzenden",
        receiveCC: "Voeg cc toe",
        noDisposableEmail: "Wegwerp e-mails zijn niet toegestaan.",
        vatValidationErr: "BTW nummer validatie is mislukt.",
        export: "Export",
        size: "Grootte",
        section: "Sectie",
        container: "Container",
        embed: "Integreren",
        ascending: "oplopend",
        descending: "Aflopend",
        sort: "Soort",
        language: "nl",
        reference: "Referentie",
        warning: "Waarschuwing",
        alreadySelected: "Al geselecteerd",
        requiredColumns: "Vereiste kolommen",
        noData: "Geen gegevens",
        report: "Rapport",
        userRoles: "Gebruikersrollen",
        atLeast13years:
          "U moet minstens 13 jaar oud zijn om Stripe te gebruiken.",
        divider: "Scheidingslijn",
        installmentPayments: "Termijnbetalingen",
        onlyOne: "slechts één",
        companyOverview: "Bedrijfsoverzicht",
        companyDetails: "Gelieve de gegevens van uw bedrijf in te vullen.",
        additionalData: "Aanvullende gegevens",
        noPayments: "Geen betalingen",
        noPayouts: "Geen uitbetalingen",
        generatePdf: "Genereren PDF",
        pleaseSelectServicesAndPackage: "Selecteer de diensten en het pakket.",
        moveServicesInNewCategory:
          "Verplaats diensten in een andere categorie.",
        changeAllServicePrices: "Verander alle dienst prijzen.",
        addServicesInselectedPackage:
          "Voeg de diensten toe in het geselecteerde pakket.",
        saveMessageAsTemplate:
          "Sla het bericht op als sjabloon voor toekomstig gebruik.",
        addressNotFound: "Adres niet gevonden",
        takeAPhoto: "Maak een foto",
        workInConstruction: "Werkt u in de bouw?",
        type: "Type",
        administrative: "Administratieve kosten",
        purchase: "Aankoop",
        workforce: "Personeelsbestand",
        sumInCash: "Ontvangen bedrag in contanten",
        create: "Creëren",
        close: "Dicht",
        selectServices: "Selecteer uw diensten",
        pleaseselectServices: "Selecteer de diensten a.u.b.",
        insertCompanyInfo: "Voeg alle bedrijfsinformatie in.",
        insertSupplier: "Voer een leverancier in.",
        selectClient: "Selecteer een klant.",
        budgetEstimate: "Budgetraming",
        invalid: "ungültig",
        valid: "geldigheid",
        confirmContract: "U moet een sjabloon voor het contract toevoegen.",
        tryAgain: "Probeer het opnieuw",
        color: "Kleur",
        curency: "Valuta",
        reminderNotificationMinAmount: "minimumwaarde voor kennisgeving",
        reminderDelay: "Herinnering vertraging",
        notificationDelay: "Kennisgevingsvertraging",
        noNewNotifications: "Geen nieuwe notificaties",
        continuousInvoiceNumbers:
          "Factuurnummers worden elk jaar gereset naar 1",
        generatedWith: "Gegenereerd met",
        currentPassword: "Huidig wachtwoord",
        pleaseSelect: "Selecteer alstublieft: ",
        selectParentCategory: "Selecteer bovenliggende categorie",
        signBelow: "Teken hieronder",
        saveSignature: "Handtekening opslaan",
        erase: "Wissen",
        pressToSign: "Klik hier om te tekenen",
        termsAndConditions:
          "Ik heb de algemene voorwaarden gelezen en accepteer ze.",
        confirm: "Bevestigen",
        accept: "Aanvaarden",
        minOneLetterAndNumber:
          "Het wachtwoord moet ten minste één letter en één cijfer bevatten.",
        min8Character: "Het wachtwoord moet uit ten minste 8 tekens bestaan.",
        emailNotValid: "Het ingevoerde e-mailadres is ongeldig.",
        fieldRequired: "Dit veld is verplicht.",
        passwordMismatch: "De ingevoerde wachtwoorden komen niet overeen.",
        cancel: "Annuleer",
        disableAccount: "Weet u zeker dat u deze account wilt uitschakelen?",
        saveService: "Weet je zeker dat je deze dienst wilt redden?",
        yes: "Ja",
        no: "Nee",
        register: "Registreren",
        back: "Terug",
        design: "Ontwerp",
        about: "Wat betreft",
        workPerHour: "Werk per uur",
        perDay: "Par dag",
        perHour: "Par uur",
        inSelectedInterval: "In geselecteerde interval",
        toLargeOrWrongFormat:
          "Het bestand is te groot of het formaat is niet compatibel.",
        badpayee: "Slechte begunstigde",
        success: "Succes",
        successfullyCreated: "Uw Stripe-account is succesvol aangemaakt.",
        successfullyDisabled: "Uw Stripe account is gedeactiveerd.",
        modifyPrices: "Prijzen wijzigen",
        pricePerHour: "Prijs per uur",
        trimester: "Trimester",
        withBreak: "Minus pauze",
        addAnnualCost: "Jaarlijkse kosten toevoegen",
        dailyBreak: "Dagelijkse pauze",
        minutes: "minuten",
        stop: "Stoppen",
        available: "Beschikbaar",
        pending: "In afwachting",
        balance: "Rekeningsaldo",
        industry: "Industrie",
        verifyIdentityDocument: "Identiteitsbewijs verifiëren",
        verifyHomeAddress: "Verifieer thuisadres",
        payouts: "Uitbetalingen",
        personalIdNumber: "Persoonlijk identificatienummer",
        familyName: "Achternaam",
        dateOfBirth: "Geboortedatum",
        bancontact: "Bancontact",
        modifyPricesByPercent: "Verander de prijzen in procenten.",
        enable: "Activeer",
        enableOnlinePayments: "Online-Zahlungen aktivieren",
        vatNoLength: 10,
        serviceName: "Voer de naam van de dienst in.",
        minAmount: "Minimale hoeveelheid",
        series: "Serie",
        monthly: "Maandelijks",
        upgrade: "Upgrade",
        subscription: "Abonnement",
        rename: "Hernoemen",
        import: "Importeren",
        getSubscription: "Krijg abonnement",
        upgradeSubscription: "Uw abonnement bijwerken",
        doc: "Document",
        day: "dag",
        trial: "Proefperiode",
        finish: "Afwerking",
        linkCopied: "Link gekopieerd",
        next: "Volgende",
        tutWelcomeTitle: "Welkom!",
        tutWelcomeContent:
          "Dit is een zeer kort overzicht van de interface om in de kortst mogelijke tijd productief te zijn.",
        tutLeftMenuTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Het hoofdmenu.",
        tutLeftMenuContent:
          "In het hoofdmenu vindt u alle aangemaakte documenten. De rapporten kunnen verschillende soorten analyses bevatten, alsmede gedetailleerde informatie over de onderneming.",
        tutNewDocumentTitle: "Maak een nieuw document of een project.",
        tutNewDocumentContent:
          "Waar u zich ook bevindt in de toepassing, hier kunt u een nieuw document of project aanmaken.  <span class='glyphicon glyphicon-chevron-right'></span>",
        tutNotoficationsTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Notificaties.",
        tutNotoficationsContent:
          "Hier kunt u de meldingen zien die door de toepassing worden gegenereerd.",
        tutOptionsTitle:
          "Opties en uitloggen. <span class='glyphicon glyphicon-chevron-left'></span>",
        tutOptionsContent:
          "Aan de rechterkant van de interface hebt u toegang tot de opties en kunt u de toepassing sluiten.",
        tutDocumentInfoTitle: "Nieuw Document.",
        tutDocumentInfoContent:
          "Het document bestaat uit twee delen, één voor algemene informatie en één voor diensten en producten. De basisinformatie staat bovenaan in de interface. Om het document op te slaan klikt u op de knop Opslaan.",
        tutDocumentDetailsTitle: "Producten en diensten toevoegen.",
        tutDocumentDetailsContent:
          "Hier kunt u diensten en producten toevoegen door op de + knop bovenaan te klikken of door het eerste product uit het menu aan de rechterkant toe te voegen. Zij zullen het eerder gemaakte document automatisch opslaan.",

        foldersTutBasicInfo:
          "Hier wijzigt u de gegevens in het klantenbestand en de plaats waar de diensten worden geleverd.",
        foldersTutInvoices: "Opbrengsten. Proformafacturen en creditnota's.",
        foldersTutInvoicesDes:
          "Maak een nieuw document op basis van het geselecteerde tabblad. Druk op de knop + (plus) om een nieuw document in de actieve project te maken. Deze zullen ook zichtbaar zijn in de lijst met alle documenten (proforma-facturen ...). Als u een verkeerde factuur aan het bestand hebt toegevoegd, kan deze worden gedisassocieerd door deze te selecteren en op de - (min) knop te drukken. Onderaan vindt u samenvattende informatie over de documenten in de geselecteerde spreadsheet.",
        foldersTutOrderTickets: "Bonnen en documenten voor onderaannemers.",
        foldersTutCostsDes:
          "Voeg projectgerelateerde kosten toe om de financiële situatie van de zaak te controleren.",
        foldersFileExplorerDes:
          "Per e-mail verstuurde projectdocumenten worden hier ook in PDF-formaat opgeslagen. U kunt service-gerelateerde records toevoegen om alle informatie op één plaats te krijgen en problemen met verwarring bij klanten te voorkomen.",

        docTutBasicInfo: "Bewerk hier het document en de klantgegevens.",
        docTutWorkloadSubdivision:
          "Om documenten te maken, kunt u onderverdelingen maken die passen bij de geleverde diensten.",
        docTutCategories:
          "Binnen deze onderverdelingen zijn de diensten onderverdeeld in categorieën en subcategorieën. De diensten die u aanmaakt zullen in de gekozen categorie en onderverdeling worden geplaatst. Als deze niet worden gekozen, worden de diensten toegevoegd aan de standaardcategorie en -onderverdeling.",
        docTutAddDeleteService:
          "De + (plus) knop voegt een nieuwe dienst toe aan uw lijst van diensten. De knop ernaast (bin) verwijdert de geselecteerde diensten uit het document.",
        docTutCategoryDescription:
          "De documentcategorieën kunnen een ruimere omschrijving hebben. Hier kan het worden geschreven en opgeslagen door op de knop aan de rechterkant te drukken.",
        docTutService:
          "De dienst is aanvankelijk leeg. Door de naam in te vullen, kunt u kiezen uit de lijst van eerder opgeslagen dienstvoorstellen. U maakt een nieuwe aan als u niets selecteert. De diensten worden automatisch opgeslagen wanneer u naar het volgende veld gaat.",
        docTutServiceDetails:
          "De nieuwe diensten kunnen in detail worden gewijzigd door te klikken op de knop opties ... (drie stippen). Na wijziging wordt het automatisch opgeslagen in het document, maar het kan worden bijgewerkt in de service database door de Save knop, rechtsboven.",
        docTutTotals:
          "Onderaan worden de totalen automatisch bijgewerkt met de toegevoegde diensten.",
        docTutDocumentPreview:
          "De voorbeeldknop genereert het document in afdrukbaar formaat. Het kan worden bekeken, afgedrukt, naar de klant gemaild of van aantekeningen worden voorzien.",
        docTutBlockUnblock:
          "De knoppen vergrendelen/ontgrendelen kunnen alle velden in het document vergrendelen, zodat ze niet kunnen worden gewijzigd.",
        docTutOtherOptions:
          "Afhankelijk van het document in het menu, verschijnen andere specifieke opties voor elk type document.",

        createRecurrent:
          "De factuur wordt automatisch gegenereerd met het geselecteerde interval van dagen. Wilt u doorgaan?",
        recurrenceInterval: "Herhalingsinterval (Dagen)",
        recurrentInvoice: "Herhalende factuur",
        paymentDeadline: "Betalingsdatum",
        withoutVat: "Zonder BTW",
        notUsed: "Niet gebruikt",
        dashboard: "Dashboard",
        choosePlan: "Kies plan",
        chooseMonth: "Kies maand",
        sepaTerms:
          "Door dit mandaatformulier te ondertekenen, machtigt u Cronoptimal om instructies naar uw bank te sturen om uw rekening te debiteren, en uw bank om uw rekening te debiteren in overeenstemming met de instructies van Cronoptimal. U heeft recht op terugbetaling door uw bank onder de voorwaarden beschreven in de overeenkomst die u met haar heeft afgesloten. Alle verzoeken om terugbetaling moeten binnen 8 weken na de datum waarop uw rekening werd gedebiteerd worden ingediend. Gelieve de velden met een * in te vullen.",
        pay: "Betalen",
        youChoose: "U hebt gekozen voor de",
        profit: "Winst",
        overview: "Overzicht",
        from: "van",
        secondTo: "tot",

        January: "Januari",
        February: "Februari",
        March: "Maart",
        April: "April",
        May: "Mei",
        June: "Juni",
        July: "Juli",
        August: "Augustus",
        September: "September",
        October: "Oktober",
        November: "November",
        December: "December",

        clientTel: "Client telefoonnummer",
        clientEmail: "Client Email",
        header: "Koptekst",
        footer: "Footer",
        default: "Standaard",
        userDefined: "Gebruiker gedefinieerd",
        hour: "uur",
        actions: "Acties",
        bulkActions: "Bulkacties",
        spent: "Uitgegeven",
        deleteAssociated: "Geassocieerde documenten worden verwijderd.",
        newDocument: "Nieuw document",
        invoice: "Factuur",
        paid: "Betaald",
        unpaid: "Onbetaald",
        payment: "Betaling",
        proforma: "Pro-forma",
        creditNote: "Kredietnota",
        creditNotes: "Kredietnotas",
        reminder: "Herinnering",
        number: "Nummer",
        date: "Datum",
        amount: "Amount",
        elapsedTime: "Verlopen tijd",
        days: "Dagen",
        notice: "Opmerking",
        page: "Pagina",
        preview: "Voorbeeld",
        previewSend: "Voorbeeld bekijken en verzenden",
        update: "Update",
        attestation: "Attestatie",
        totalNoVat: "Totaal zonder BTW",
        totalWithVat: "Totaal met BTW",
        restToPay: "Bedrag verschuldigd",
        gift: "Cadeau",
        orderTicket: "Bestelkaartje",
        notsaved: "Niet opgeslagen",
        saved: "Opgeslagen",
        selectImages: "Selecteer de afbeeldingen",
        nothingSelected: "Niets geselecteerd",
        deleteItem: "Wilt u het item verwijderen?",
        original: "Origineel",
        add: "Voeg toe",
        modify: "wijzigen",
        name: "Naam",
        quantity: "Hoeveelheid",
        options: "Opties",
        unitPrice: "Eenheidsprijs",
        description: "Beschrijving",
        materialsCost: "Materialen kosten",
        laborCost: "Arbeidskosten",
        laborTime: "Arbeidstijd",
        articles: "Artikelen",
        article: "Artikel",
        workloadSubdivision: "Werkladingonderverdeling",
        totalMerchandisesNoVat: "Totale kosten",
        totalPayment: "Totale betaling",
        leftToPay: "Om te betalen",
        vat: "BTW",
        disabled: "Uitgeschakeld",
        disable: "Onbruikbaar maken",
        remove: "Verwijder",
        delete: "Verwijderen",
        select: "Selecteer",
        save: "Opslaan",
        documents: "Documenten",
        lastDocuments: "Laatste documenten",
        estimates: "Schattingen",
        orderTickets: "Bestel Tickets",
        invoices: "Facturen",
        received: "Ontvangen",
        suplementSheet: "Voorblad",
        companyContract: "Bedrijfscontract",
        vatAttestation: "BTW Attestation",
        pvTemporarReception: "PV temporar receptie",
        pvDefinitiveReception: "PV definitieve ontvangst",
        heatingWorkTicket: "Verwarming werkkaartje",
        orderTicketSubcontractors: "Onderaannemersbestelkaartje ",
        subcontractorContract: "Onderaannemerscontract",
        generalConditions: "Algemene voorwaarden",

        receipts: "Ontvangsten",
        proformas: "Pro-formas",
        newProforma: "Nieuwe pro forma factuur",
        contracts: "Contracten",
        extraWorkReports: "Extra werkrapport",
        extraCostsReports: "Extra Costs Report",
        attachedFiles: "Bijgevoegde bestandens",
        attestationDocuments: "Attest docs",
        clients: "Klanten",
        documentTemplates: "Documenten Sjablonen",
        companyUsers: "Bedrijfsgebruikers",
        addNewUser: "Voeg een nieuwe gebruiker toe",
        basicInfo: "Basic Info",
        details: "Details",
        payments: "Betalingen",
        onlinePayments: "Online betalingen",
        extraCosts: "Extra kosten",
        other: "Andere",
        vatRate: "BTW tarief",
        client: "Client",
        selectAValue: "Kies een optie",
        addSelected: "Add Selected",
        edit: "Edit",
        extra: "Extra",
        service: "Service",
        currency: "Valuta",
        status: "Status",
        observations: "Waarnemingen",
        firstName: "Voornaam",
        email: "Email",
        webPage: "Webpagina",
        postalCode: "Postcode",
        logo: "Logo",
        address: "Adres",
        city: "Stad",
        country: "Land",
        code: "Code",
        company: "Bedrijf",
        image: "Afbeelding",

        // folders
        folder: "Project",
        folders: "Projecten",
        folderNo: "Projectnummer",
        clientAddress: "Clientadres",
        startDate: "Startdatum",
        endDate: "Einddatum",
        folderOptions: "Project opties",
        newFolder: "Nieuw project",
        modifyFolder: "Wijzigen project",
        constructionType: "Bouwtype",
        dateOfPayment: "Betalingsdatum",
        addPayment: "Betaling toevoegen",
        addExtraCost: "Extra kosten toevoegen,",
        paymentNote: "Betaalbon",
        finalPaymentNote: "Definitieve nota",
        title: "Titel",
        owner: "Eigenaar",

        // document Templates
        estimatesTemplate: "Schattingen sjabloon",
        orderTicketTemplate: "Bestelkaartje sjabloon",
        invoiceTemplate: "Factuur sjabloon",
        receiptTemplate: "Ontvangstbewijs sjabloon",
        contractTemplate: "Contract sjabloon",
        extraWorkReportTemplate: "Extra werkrapport sjabloon",
        extraCostsReportTemplate: "Extra kosten rapport sjabloon",
        attestationTemplate: "Attestatie sjabloon",
        template: "Sjabloon",

        // documents
        lastInvoices: "Laatste facturen",
        lastEstimates: "Laatste schattingen",
        lastOrderTickets: "Laatste bestelbonnen",
        lastOrderDocuments: "Laatste besteldocumenten",

        // receipts
        newReceipt: "Nieuwe ontvangstbewijs",
        receipt: "Ontvangstbewijs",

        // order tickets
        newOrderTicket: "Nieuwe bestelbonnen",

        // invoices
        newInvoice: "Nieuwe factuur",
        invoicesOptions: "Facturen Opties",

        // reports
        newReport: "Nieuw rapport",

        //estimates
        estimate: "Schatting",
        newEstimate: "Nieuwe schatting",
        estimateNo: "Schatting nummer",
        estimatesOptions: "Schattingen opties",

        // contracts
        newContract: "Nieuw contract",

        // attestation documents
        newAttestationDoc: "Nieuw Attest Document",

        // companies
        companies: "Bedrijven",
        newCompany: "Nieuw Bedrijf",
        companyInfo: "Bedrijfsinfo",
        vatNo: "BTW nummer",
        vatType: "BTW Type",
        companyRegNo: "Bedrijfsnummer",
        bankBic: "Bank BIC Code",
        bankName: "Banknaam",
        bankAccount: "Bankrekening",
        online: "Online",
        bankTransfer: "Banküberweisung",
        account: "Account",
        telFax: "Telefoon/Fax,",
        mobile: "Mobiel",
        tel: "Telefoon",
        fax: "Fax",
        secondary: "Secundair",

        // clients
        newClient: "Nieuwe klant",
        clientName: "Klantnaam",
        clientDiscount: "Klantkorting",
        contactPerson: "Contactpersoon",
        clientInfo: "Klantinformatie",
        contact: "Contact",

        // categories
        category: "Categorie",
        categories: "Categorieën",
        subcategory: "Subcategorie",

        // services
        services: "Diensten",
        measureUnit: "Meeteenheid",
        price: "Prijs",
        cost: "Kosten",
        tradeMargin: "Handelsmarge (%)",
        newService: "Nieuwe dienst",
        addServiceArticles: "Dienstartikelen toevoegen",

        // articles
        brand: "Merk",
        diameter: "Diameter",
        newArticle: "Nieuw artikel",
        coefficient: "Coëfficiënt",
        updateFromFile: "Update van bestand",
        updateFromWebpage: "Update van webpagina",
        productsFile: "Producten bestand",
        imagesFile: "Afbeeldingen bestand",
        articleGroup: "Artikelgroep",

        subcontractors: "Onderaannemers",
        subcontractors_employees: "Onderaannemers werknemers",
        company_employees: "Bedrijfswerknemers",
        newSubcontractor: "Nieuwe onderaannemer",
        subcontractor: "Onderaannemer",
        subcontractorAddress: "Onderaannemer Adres",
        parent: "Ouder",
        totalPrice: "Totale prijs",
        totalHours: "Totaal aantal uren",
        siteAddress: "Site adres",
        workSite: "Werkplaats",
        sameAsClient: "Zelfde als klant",
        emptyLine: "Lege lijn",
        value: "Waarde",
        content: "Inhoud",
        paymentDue: "Betaling verschuldigd",
        paymentMode: "Betaalmodus",
        paymentMethod: "Betaalmodus",
        bank: "Bank",
        cash: "Cash",
        downPayment: "Aanbetaling",
        discount: "Korting",
        amountNoVat: "Bedrag zonder BTW",
        amountWithVat: "Bedrag met BTW",
        message: "Bericht",
        attachment: "Bijlage",
        emailSignature: "Email handtekening",
        subject: "Onderwerp",
        to: "Naar",
        generateInvoice: "Generate factuur",
        active: "Actief",
        inactive: "Inactief",
        all: "Alles",
        year: "Jaar",
        addYear: "Jaar toevoegen",
        addNewBudgetEstimate: "Nieuwe budgetraming toevoegen",
        filter: "Filter",
        total: "Totaal",
        generate: "Genereren",
        timeElapsed: "De tijd leunde",
        start: "Start",
        middle: "Midden",
        end: "Einde",
        work: "Werk",
        signature: "Handtekening",
        reminderEmailSignature: "Email herinnering handtekening",
        estimateEmailSignature: "Email schatting handtekening",
        orderTicketEmailSignature: "Email bestel kaartje handtekening",
        startWork: "Werk starten",
        duringWork: "Tijdens het werk",
        endWork: "Einde werk",
        gallery: "Galerij",
        expenses: "Uitgaven",
        expense: "Uitgave",
        upload: "Uploaden",
        send: "Verzend",
        new: "Nieuw",
        reset: "Reset",
        reimburse: "Terugbetalen",
        subcontractorOrderTickets: "Onderaannemers / Werknemers bestel kaartje",
        subcontractorOrderTicket: "Onderaannemer / Werknemer bestel kaartjet",
        employeesOrderTickets: "Werknemers bestel kaartje",
        single: "Single",
        copy: "Kopiëren",
        paste: "Plakken",
        accounting: "Boekhouding",
        existing: "Bestaande",
        none: "Geen",
        contract: "Contract",
        employee: "Werknemer",
        employees: "Werknemers",
        activeWorkers: "Actieve werknemers",
        lastLocationToday: "Laatste locatie vandaag",
        locationAlert: "Locatiewaarschuwing",
        outOfRange: "Buiten bereik",
        search: "Zoeken",
        alerts: "Waarschuwingen",
        alertInfo: "Alert informatie",
        reports: "Rapporten",
        clocking: "Klokken",
        login: "Log in",
        logout: "Uitloggen",
        username: "Gebruiker",
        password: "Wachtwoord",
        newPassword: "Nieuw wachtwoord",
        repeatNewPassword: "Herhaal het nieuwe wachtwoord",
        repeatPassword: "Herhaal wachtwoord",
        role: "Role",
        rememberMe: "Herinner me",
        forgotPassword: "Wachtwoord vergeten?",
        passwordsDontMatch: "De wachtwoorden komen niet overeen.",
        usernameExists: "Deze gebruikersnaam bestaat al.",

        //errors
        compulsoryUserPassword: "Gebruikersnaam en wachtwoord zijn vereist.",
        wrongUserOrPassword: "De gebruikersnaam of het wachtwoord is onjuist.",

        // client errors
        clientExists: "Deze klant bestaat al.",
        clientDoesNotExist: "Deze klant bestaat niet.",

        // category errors
        categoryExists: "Deze categorie bestaat al.",
        categoryStillUsed:
          "De categorie wordt gebruikt door services. Verwijder eerst kinderdiensten.",
        deleteCategory: "Verwijder categorie en zijn services?",

        // services
        ordersWithServiceExist:
          "Projecten die deze service bevatten, bestaan. Verwijder eerst afhankelijke projecten.",
        serviceDoesNotExists: "Service bestaat niet.",
        summaryEstimates: "Samenvatting schattingen",
        // estimates
        estimateWithSameNumberExists:
          "Er bestaat al een schatting met hetzelfde nummer.",
        estimateDoesNotExists: "De schatting bestaat niet.",

        invoiceWithSameNumberExists:
          "Er bestaat al een factuur met hetzelfde nummer.",
        sameNumberExists: "Het nummer bestaat al.",

        // general errors
        notFound: "niet gevonden",
        error: "Fout",
        selectError: "Selecteer een item uit de lijst.",
        saveBaseService: "Registreer en vervang de basisdienst?",
        noInitialServiceFound: "Geen initiële dienst gevonden.",
        move: "Beweeg",
        moving: "In beweging",
        summary: "Samenvatting",
        locate: "Zoek",
        useLocation: "Gebruik locatie",
        blocked: "Geblokkeerd",
        unblocked: "Ongeblokkeerd",
        hidePrices: "Verberg prijzen",
        totalToInvoice: "Totaal om te facturen",
        amountLeftToInvoice: "Bedrag over te rekenen",
        totalAuthorizedToInvoice: "Totaal gemachtigd om te facturen",
        alreadyInvoiced: "Reeds gefactureerd",
        creditCard: "Debet/Creditcard",
        dragFiles: "Sleep bestanden hier",
        open: "Open",
        closed: "Gesloten",
        liability: "Aansprakelijkheid",
        minValue: "Minimale waarde",
        maxValue: "Maximale waarde",
        docType: "Documenttype",
        paymentTerm: "Betalingstermijn",
        notifications: "Meldingen",
        notification: "Kennisgeving",
        supplier: "Leverancier",
        equivalent: "Gelijkwaardig",
        alreadyPaid: "Al betaald",
        costs: "Kosten",
        presenceOfContractor:
          "In aanwezigheid van de contractant (of zijn vertegenwoordiger)",
        presenceOfDeveloper: "In aanwezigheid van de projectleider",
        absenceOfContractor:
          "Bij afwezigheid van de contractant, naar behoren opgeroepen per aangetekende brief met ontvangstbevestiging",
        contractor: "Aannemer",
        developer: "Project eigenaar",
        assistant: "Assistent",
        warranty: "Garantie",
        actualCosts: "Werkelijke kosten",
        estimatedCosts: "Geschatte kosten",
        omniumWarranty:
          "The Omnium - Het omvat alles: vervoer, uurlonen en reserveonderdelen om te vervangen",
        regularWarranty:
          "Garantie - Alleen het defecte onderdeel wordt gratis vervangen. Uurloon en vervoer moeten betaald worden",
        digressiveWarranty:
          "De Digressive Garantie - Als de gewone garantie, behalve dat u na een aantal jaren een bepaald percentage van het defecte onderdeel moet betalen.",
        unauthorized: "Toegang is niet toegestaan",
        addText: "Tekst toevoegen",
        groupName: "Groepsnaam",
        duplicate: "Dupliceren",
        optional: "Optioneel",
        invoicing: "Facturatie",
        costManagement: "Kostenbeheer",
        representativeName: "Representatieve naam",
        generalInfo: "Algemene informatie",
        bankInfo: "Bankinformatie",
        admin: "Administrator",
        timekeeping: "Tijdopruiming",
        map: "Kaart",
        clientType: "Client type",
        tenant: "Huurder",
        usufructuary: "Vruchtgebruiker",
        manager: "Manager",
        packages: "Pakketten",
        package: "Pakket",
        docNo: "Documentnummer",
        companyName: "Bedrijfsnaam",
        companyAddress: "Bedrijfsadres",
        paymentDate: "Betalingsdatum",
        clientNo: "Klantcode",
        clientVatNo: "Klant btw nummer",
        up: "Omhoog",
        upgradeForMoreClients: "Wijzig het abonnementsplan voor meer klanten.",
        reconnect:
          "Er is een fout opgetreden bij het ophalen van de gegevens. Gelieve opnieuw in te loggen.",
      },
      de: {
        january: "Januar",
        february: "Februar",
        march: "Marsch",
        april: "April",
        may: "Kann",
        june: "Juni",
        july: "Juli",
        august: "August",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "Dezember",
        home: "Heim",
        invalidDate: "Ungültiges Datum",
        showMore: "Mehr anzeigen",
        showLess: "Weniger anzeigen",
        estimatedTotal: "Geschätztes Gesamt",
        actualTotal: "Tatsächliches Gesamt",
        offer: "Schatting",
        months: "Monate",
        navigation: "Navigation",
        noActivity:
          "Es wurden keine Aktivitäten festgestellt. Sie werden in Kürze abgemeldet.",
        noEmployees: "Keine Mitarbeiter verfügbar.",
        hr: "Humanressourcen",
        additionalInformation: "Weitere Informationen",
        emailNotConfirmed:
          "Die E-Mail-Adresse ist noch nicht bestätigt worden.",
        pleaseConfirmEmail:
          "Eine Bestätigungs-E-Mail wurde an die angegebene Adresse gesendet.",
        confirmEmail: "Bitte bestätigen Sie Ihre E-Mail, um fortzufahren.",
        confirmedEmail: "Ihre E-Mail wurde bestätigt.",
        resendEmail: "E-Mail erneut senden",
        receiveCC: "cc hinzufügen",
        noDisposableEmail: "Wegwerf-E-Mails sind nicht zulässig.",
        vatValidationErr:
          "Die Überprüfung der Mehrwertsteuernummer ist fehlgeschlagen.",
        export: "Exportieren",
        size: "Größe",
        section: "Sektion",
        container: "Container",
        embed: "Einbetten",
        ascending: "Aufsteigend",
        descending: "Absteigend",
        sort: "Sortieren",
        language: "de",
        reference: "Referenz",
        loading: "Wird geladen",
        warning: "Warnung",
        alreadySelected: "Bereits ausgewählt",
        requiredColumns: "Erforderliche Spalten",
        noData: "Keine Daten",
        report: "Bericht",
        userRoles: "Benutzerregeln",
        atLeast13years:
          "Sie sollten mindestens 13 Jahre alt sein, um Stripe zu verwenden",
        divider: "Teiler",
        installmentPayments: "Ratenzahlungen",
        onlyOne: "nur eine",
        companyOverview: "Firmenüberblick",
        companyDetails: "Bitte geben Sie die Daten Ihres Unternehmens an.",
        additionalData: "Zusätzliche Daten",
        noPayments: "Keine Zahlungen",
        noPayouts: "Keine Auszahlungen",
        generatePdf: "Generieren PDF",
        pleaseSelectServicesAndPackage:
          "Bitte wählen Sie die Dienstleistungen und das Paket aus.",
        moveServicesInNewCategory:
          "Verschieben Sie Dienstleistungen in eine andere Kategorie.",
        changeAllServicePrices: "Ändern Sie alle Dienstleistungspreise.",
        addServicesInselectedPackage:
          "Fügen Sie die Dienste im ausgewählten Paket hinzu.",
        saveMessageAsTemplate:
          "Speichern Sie die Nachricht als Vorlage zur späteren Verwendung.",
        addressNotFound: "Adresse nicht gefunden",
        takeAPhoto: "Foto machen",
        workInConstruction: "Sind Sie im Baugewerbe tätig?",
        type: "Typ",
        administrative: "Administrative Kosten",
        purchase: "Kauf",
        workforce: "Belegschaft",
        sumInCash: "Summe erhalten Bargeld",
        create: "Erstellen",
        close: "Schließen",
        selectServices: "Wählen Sie Ihre Dienste aus",
        pleaseselectServices: "Bitte wählen Sie die Dienste aus.",
        insertCompanyInfo:
          "Bitte geben Sie alle Unternehmensinformationen ein.",
        insertSupplier: "Bitte geben Sie einen Lieferanten ein.",
        selectClient: "Bitte wählen Sie einen Kunden aus.",
        budgetEstimate: "Budgetschätzung",
        invalid: "ungültig",
        valid: "gültigkeit",
        confirmContract: "Sie müssen eine Vorlage für den Vertrag hinzufügen.",
        tryAgain: "Versuch es noch einmal",
        color: "Farbe",
        curency: "Währung",
        reminderNotificationMinAmount: "Mindestwert für die Benachrichtigung",
        reminderDelay: "Erinnerungsverzögerung",
        notificationDelay: "Benachrichtigungsverzögerung",
        noNewNotifications: "Keine neuen Benachrichtigungen",
        continuousInvoiceNumbers:
          "Rechnungsnummern werden jedes Jahr auf 1 zurückgesetzt",
        generatedWith: "Generiert mit",
        currentPassword: "Aktuelles Passwort",
        pleaseSelect: "Bitte auswählen: ",
        selectParentCategory: "Wählen Sie die übergeordnete Kategorie aus",
        signBelow: "Unterschreiben Sie unten",
        saveSignature: "Unterschrift speichern",
        erase: "Löschen",
        pressToSign: "Klicken Sie hier, um zu unterschreiben",
        termsAndConditions:
          "Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere sie.",
        confirm: "Bestätigen",
        accept: "Akzeptieren",
        minOneLetterAndNumber:
          "Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten.",
        min8Character: "Das Passwort muss mindestens 8 Zeichen lang sein.",
        emailNotValid: "Die eingegebene E-Mail ist ungültig.",
        fieldRequired: "Dieses Feld wird benötigt.",
        passwordMismatch:
          "Die de eingegebenen Passwörter stimmen nicht überein.",
        cancel: "Stornieren",
        disableAccount:
          "Sind Sie sicher, dass Sie dieses Konto deaktivieren möchten?",
        saveService: "Sind Sie sicher, dass Sie diesen Dienst retten wollen?",
        yes: "Ja",
        no: "Nein",
        register: "Registrieren",
        back: "Zurück",
        design: "Design",
        about: "Wat betreft",
        workPerHour: "Arbeit pro stunde",
        perDay: "Par tag",
        perHour: "Par stunde",
        inSelectedInterval: "In ausgewähltem intervall",
        toLargeOrWrongFormat:
          "Die Datei ist zu groß oder das Format ist inkompatibel.",
        badpayee: "Schlechter Zahlungsempfänger",
        success: "Erfolg",
        successfullyCreated: "Ihr Stripe-Konto wurde erfolgreich erstellt.",
        successfullyDisabled: "Ihr Stripe-Konto wurde deaktiviert.",
        modifyPrices: "Preise ändern",
        pricePerHour: "Preis pro stunde",
        trimester: "Trimester",
        withBreak: "Minus pause",
        addAnnualCost: "Jährliche Kosten hinzufügen",
        dailyBreak: "Tägliche pause",
        minutes: "minuten",
        stop: "Halt",
        available: "Verfügbar",
        pending: "Steht aus",
        balance: "Kontostand",
        industry: "Industrie",
        verifyIdentityDocument: "Identitätsdokument überprüfen",
        verifyHomeAddress: "Verifiziere die Heimatadresse",
        payouts: "Auszahlungen",
        personalIdNumber: "Persönliche ID-nummer",
        familyName: "Nachname",
        dateOfBirth: "Geburtsdatum",
        bancontact: "Bancontact",
        modifyPricesByPercent: "Preise prozentual ändern.",
        enable: "Aktivieren",
        enableOnlinePayments: "Online betalingen inschakelen",
        vatNoLength: 9,
        serviceName: "Bitte geben Sie den Namen des Dienstes ein.",
        minAmount: "Mindestbetrag",
        series: "Serie",
        monthly: "Monatlich",
        upgrade: "Upgrade",
        subscription: "Abonnement",
        rename: "Umbenennen",
        import: "Importieren",
        getSubscription: "Ein Abonnement erhalten",
        upgradeSubscription: "Ihr Abonnement aktualisieren",
        doc: "Dokument",
        day: "Tag",
        trial: "Probezeit",
        finish: "Fertig",
        linkCopied: "Link kopiert",
        next: "Nächster",
        tutWelcomeTitle: "Herzlich willkommen!",
        tutWelcomeContent:
          "Dies ist ein sehr kurzer Überblick über die Schnittstelle, um in kürzester Zeit produktiv zu sein.",
        tutLeftMenuTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Das Hauptmenü.",
        tutLeftMenuContent:
          "Im Hauptmenü finden Sie alle erstellten Dokumente. Die Berichte können verschiedene Arten von Analysen sowie detaillierte Informationen über das Unternehmen liefern.",
        tutNewDocumentTitle:
          "Erstellen Sie ein neues Dokument oder ein neues Projekt.",
        tutNewDocumentContent:
          "Wo auch immer Sie sich in der Anwendung befinden, hier können Sie ein neues Dokument oder ein neues Projekt erstellen.  <span class='glyphicon glyphicon-chevron-right'></span>",
        tutNotoficationsTitle:
          "<span class='glyphicon glyphicon-chevron-left'></span> Benachrichtigungen.",
        tutNotoficationsContent:
          "Hier sehen Sie die von der Anwendung erzeugten Benachrichtigungen.",
        tutOptionsTitle:
          "Optionen und Abmelden. <span class='glyphicon glyphicon-chevron-left'></span>",
        tutOptionsContent:
          "Auf der rechten Seite der Benutzeroberfläche können Sie auf die Optionen zugreifen und Sie können die Anwendung schließen.",
        tutDocumentInfoTitle: "Neues Dokument.",
        tutDocumentInfoContent:
          "Das Dokument besteht aus zwei Teilen, einem für allgemeine Informationen und einem für Dienstleistungen und Produkte. Die grundlegenden Informationen befinden sich oben auf der Benutzeroberfläche. Um das Dokument zu speichern, klicken Sie auf die Schaltfläche Speichern.",
        tutDocumentDetailsTitle:
          "Hinzufügen von Produkten und Dienstleistungen.",
        tutDocumentDetailsContent:
          "Hier können Sie Dienstleistungen und Produkte hinzufügen, indem Sie oben auf die Schaltfläche + klicken oder das erste Produkt aus dem Menü auf der rechten Seite hinzufügen. Sie werden das zuvor erstellte Dokument automatisch speichern.",

        foldersTutBasicInfo:
          "Hier ändern Sie die Informationen in der Kundendatei und den Ort, an den die Dienstleistungen geliefert werden.",
        foldersTutInvoices: "Umsatz. Proforma Rechnungen und Gutschriften.",
        foldersTutInvoicesDes:
          "Erstellen Sie ein neues Dokument basierend auf der ausgewählten Registerkarte. Drücken Sie die Taste + (Plus), um ein neues Dokument im aktiven Projekt zu erstellen. Diese werden auch in der Liste aller Dokumente (Proforma-Rechnungen ...) sichtbar sein. Wenn Sie der Datei eine falsche Rechnung hinzugefügt haben, kann die Zuordnung aufgehoben werden, indem Sie sie auswählen und die Taste - (Minus) drücken. Im unteren Bereich haben Sie zusammenfassende Informationen zu den Dokumenten in der ausgewählten Tabelle.",
        foldersTutOrderTickets: "Belege und Dokumente für Subunternehmer.",
        foldersTutCostsDes:
          "Fügen Sie projektbezogene Kosten hinzu, um die finanzielle Situation des Falls zu verfolgen.",
        foldersFileExplorerDes:
          "Auch per E-Mail versandte Projektdokumente werden hier im PDF-Format gespeichert. Sie können dienstleistungsbezogene Datensätze hinzufügen, um alle Informationen an einem Ort zu erhalten und Kundenverwirrung zu vermeiden.",

        docTutBasicInfo:
          "Bearbeiten Sie hier das Dokument und die Informationen über den Klienten.",
        docTutWorkloadSubdivision:
          "Um Dokumente zu erstellen, können Sie Untergliederungen anlegen, die sich in die erbrachten Leistungen einfügen.",
        docTutCategories:
          "In diesen Untergliederungen werden die Dienste in Kategorien und Unterkategorien unterteilt. Die von Ihnen erstellten Dienste werden in der gewählten Kategorie und Unterteilung platziert. Wenn diese nicht ausgewählt sind, werden die Dienste der Standardkategorie und -unterteilung hinzugefügt.",
        docTutAddDeleteService:
          "Die Schaltfläche + (Plus) fügt eine neue Abteilung zu Ihrer Abteilungsliste hinzu. Die Schaltfläche daneben (Mülleimer) entfernt die ausgewählten Dienste aus dem Dokument.",
        docTutCategoryDescription:
          "Die Kategorien des Dokuments können eine breitere Beschreibung haben. Hier kann sie geschrieben und gespeichert werden, indem Sie auf die Schaltfläche rechts drücken.",
        docTutService:
          "Der Dienst ist zunächst leer. Wenn Sie den Namen ausfüllen, können Sie aus der Liste der Vorschläge für zuvor gespeicherte Dienste auswählen. Sie legen einen neuen an, wenn Sie nichts auswählen. Die Dienste werden automatisch gespeichert, wenn Sie zum nächsten Feld wechseln.",
        docTutServiceDetails:
          "Die neuen Dienste können im Detail geändert werden, indem Sie auf Optionen ... klicken. (drei Punkte) geändert werden. Nach der Änderung wird sie automatisch im Dokument gespeichert, kann aber über die Schaltfläche Speichern oben rechts in der Dienstleistungsdatenbank aktualisiert werden.",
        docTutTotals:
          "Unten werden die Summen automatisch aktualisiert, je nachdem, welche Dienste hinzugefügt wurden.",
        docTutDocumentPreview:
          "Die Vorschauschaltfläche erzeugt das Dokument im druckbaren Format. Es kann als Vorschau angezeigt, ausgedruckt, per E-Mail an den Kunden geschickt oder mit Anmerkungen versehen werden.",
        docTutBlockUnblock:
          "Die Schaltflächen zum Sperren/Entsperren können alle Felder im Dokument blockieren, sodass sie nicht mehr bearbeitet werden können.",
        docTutOtherOptions:
          "Je nach Dokument im Menü erscheinen weitere Optionen, die spezifisch für den jeweiligen Dokumententyp sind.",

        createRecurrent:
          "Die Rechnung wird automatisch in dem von Ihnen gewählten Tagesintervall erstellt. Möchten Sie fortfahren?",
        recurrenceInterval: "Wiederholungsintervall (Tage)",
        recurrentInvoice: "Wiederkehrende Rechnung",
        paymentDeadline: "Zahlungsfrist",
        withoutVat: "Ohne VAT",
        notUsed: "Nicht benutzt",
        dashboard: "Instrumententafel",
        choosePlan: "Wählen Sie einen Plan",
        chooseMonth: "Wählen Sie den Monat",
        sepaTerms:
          "Mit der Unterzeichnung dieses Mandatsformulars ermächtigen Sie Cronoptimal, Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und Ihre Bank, Ihr Konto in Übereinstimmung mit den Anweisungen von Cronoptimal zu belasten. Sie haben ein Recht auf Rückerstattung durch Ihre Bank unter den Bedingungen, die in der Vereinbarung, die Sie mit ihr ausgegeben haben, beschrieben sind. Alle Erstattungsanträge müssen innerhalb von 8 Wochen ab dem Datum der Abbuchung von Ihrem Konto eingereicht werden. Bitte füllen Sie die mit * gekennzeichneten Felder aus.",
        pay: "Zahlen",
        youChoose: "Sie haben sich für die entschieden",
        profit: "Profit",
        overview: "Überblick",
        from: "von",
        secondTo: "bis",

        January: "Januar",
        February: "Februar",
        March: "März",
        April: "April",
        May: "Mai",
        June: "Juni",
        July: "Juli",
        August: "August",
        September: "September",
        October: "Oktober",
        November: "November",
        December: "Dezember",

        clientTel: "Kundennummer",
        clientEmail: "Kunde E-Mail",
        header: "Header",
        footer: "Footer",
        default: "Standard",
        userDefined: "Benutzerdefinierte",
        hour: "Stunde",
        actions: "Aktionen",
        bulkActions: "Massenaktionen",
        spent: "Verbraucht",
        deleteAssociated: "Zugehörige Dokumente werden gelöscht.",
        newDocument: "Neues Dokument",
        invoice: "Rechnung",
        paid: "Bezahlt",
        unpaid: "Unbezahlt",
        payment: "Zahlung",
        proforma: "Pro-forma",
        creditNote: "Gutschrift",
        creditNotes: "Gutschriften",
        reminder: "Mahnung",
        number: "Nummer",
        date: "Datum",
        amount: "Menge",
        elapsedTime: "Verstrichene Zeit",
        days: "Tage",
        notice: "Benachrichtigung",
        page: "Seite",
        preview: "Vorschau",
        previewSend: "Vorschau und senden",
        update: "Aktualisieren",
        attestation: "Bescheinigung",
        totalNoVat: "Summe ohne Mehrwertsteuer",
        totalWithVat: "Summe mit MwSt",
        restToPay: "Offener Betrag",
        gift: "Geschenk",
        orderTicket: "Bestellungsticket",
        notsaved: "Nicht gespeichert",
        saved: "Gerettet",
        selectImages: "Wählen Sie die Bilder aus",
        nothingSelected: "Nichts ausgewählt",
        deleteItem: "Möchten Sie das Element löschen?",
        original: "Original",
        add: "Hinzufügen",
        modify: "Ändern",
        name: "Name",
        quantity: "Anzahl",
        options: "Optionen",
        unitPrice: "Einzelpreis",
        description: "Beschreibung",
        materialsCost: "Materialkosten",
        laborCost: "Arbeitskosten",
        laborTime: "Arbeitszeit",
        articles: "Artikel",
        article: "Artikel",
        workloadSubdivision: "Arbeitslast Unterteilung",
        totalMerchandisesNoVat: "Gesamtkosten",
        totalPayment: "Gesamtzahlung",
        leftToPay: "zu zahlen",
        vat: "MwSt",
        disabled: "Behindert",
        disable: "Deaktivieren",
        remove: "Entfernen",
        delete: "Löschen",
        select: "Wählen",
        save: "Sparen",
        documents: "Unterlagen",
        lastDocuments: "Letzte dokumente",
        estimates: "Schätzt",
        orderTickets: "Bestellen",
        invoices: "Rechnungen",
        received: "Empfangen",
        suplementSheet: "Ergänzungsblatt",
        companyContract: "Firmenvertrag",
        vatAttestation: "MwSt. Bestätigung",
        pvTemporarReception: "PV vorübergehender Empfang",
        pvDefinitiveReception: "PV endgültige Aufnahme",
        heatingWorkTicket: "Heizungsfahrkarte",
        orderTicketSubcontractors: "Auftragsticket-Subunternehmer",
        subcontractorContract: "Subunternehmer Vertrag",
        generalConditions: "Allgemeine Bedingungen",

        receipts: "Einnahmen",
        proformas: "Pro-formas",
        newProforma: "Neue Pro-forma-Rechnung",
        contracts: "Verträge",
        extraWorkReports: "Zusätzliche Arbeit Bericht",
        extraCostsReports: "Zusätzliche Arbeit Bericht",
        attachedFiles: "Angehängte Dokumente",
        attestationDocuments: "Bestätigungsdokumente",
        clients: "Kunden",
        documentTemplates: "Dokumente Vorlagen",
        companyUsers: "Firmenbenutzer",
        addNewUser: "Neuen Benutzer hinzufügen",
        basicInfo: "Basisinformation",
        details: "Details",
        payments: "Zahlungen",
        onlinePayments: "Online-Zahlungen",
        extraCosts: "Zusätzliche Kosten",
        other: "Andere",
        vatRate: "Mehrwertsteuer",
        client: "Klient",
        selectAValue: "Wähle eine Option",
        addSelected: "Auswahl hinzufügen",
        edit: "Bearbeiten",
        extra: "Extra",
        service: "Bedienung",
        currency: "Währung",
        status: "Status",
        observations: "Beobachtungen",
        firstName: "Vorname",
        email: "Email",
        webPage: "Website",
        postalCode: "Postleitzahl",
        logo: "Logo",
        address: "Adresse",
        city: "Stadt",
        country: "Land",
        code: "Code",
        company: "Unternehmen",
        image: "Bild",

        // folders
        folder: "Projekt",
        folders: "Projekte",
        folderNo: "Projektnummer",
        clientAddress: "Kundenadresse",
        startDate: "Anfangsdatum",
        endDate: "Enddatum",
        folderOptions: "Projektoptionen",
        newFolder: "Neuer Projekt",
        modifyFolder: "Projekt ändern",
        constructionType: "Bauart",
        dateOfPayment: "Zahlungsdatum",
        addPayment: "Zahlung hinzufügen",
        addExtraCost: "Zusätzliche Kosten hinzufügen",
        paymentNote: "Zahlungshinweis",
        finalPaymentNote: "Schlussnote",
        title: "Titel",
        owner: "Eigentümer",

        // document Templates
        estimatesTemplate: "Schätzt Vorlage",
        orderTicketTemplate: "Bestellungstabelle Vorlage",
        invoiceTemplate: "Rechnungsvorlage",
        receiptTemplate: "Quittungsvorlage",
        contractTemplate: "Vertragsvorlage",
        extraWorkReportTemplate: "Zusätzliche Arbeitsberichtvorlage",
        extraCostsReportTemplate: "Extrakostenbericht Vorlage",
        attestationTemplate: "Bestätigungsvorlage",
        template: "Vorlage",

        // documents
        lastInvoices: "Letzte Rechnungen",
        lastEstimates: "Letzte Schätzungen",
        lastOrderTickets: "Letzte Bestellung",
        lastOrderDocuments: "Letzte Auftragsdokumente",

        // receipts
        newReceipt: "Neue Quittung",
        receipt: "Eingang",

        // order tickets
        newOrderTicket: "Neue Bestellung",

        // invoices
        newInvoice: "Neue Rechnung",
        invoicesOptions: "Rechnungsoptionen",

        // reports
        newReport: "Neuer Bericht",

        //estimates
        estimate: "Schätzen",
        newEstimate: "Neue Schätzung",
        estimateNo: "Schätzung Anzahl",
        estimatesOptions: "Schätzt Optionen",

        // contracts
        newContract: "Neuer Vertrag",

        // attestation documents
        newAttestationDoc: "Neues Bestätigungsdokument",

        // companies
        companies: "Firmen",
        newCompany: "Neue Firma",
        companyInfo: "Firmeninfo",
        vatNo: "Umsatzsteuer-Identifikationsnummer",
        vatType: "MwSt. Typ",
        companyRegNo: "Handelsregisternummer",
        bankBic: "Bank BIC Code",
        bankName: "Bank Name",
        bankAccount: "Bank Konto",
        online: "Online",
        account: "Konto",
        telFax: "Telefon/Fax",
        mobile: "Mobile",
        tel: "Telefon",
        fax: "Fax",
        secondary: "Sekundär",

        // clients
        newClient: "Neuer Kunde",
        clientName: "Kundenname",
        clientDiscount: "Kundenrabatt",
        contactPerson: "Gesprächspartner",
        clientInfo: "Kundeninformation",
        contact: "Kontakt",

        // categories
        category: "Kategorie",
        categories: "Kategorien",
        subcategory: "Unterkategorie",

        // services
        services: "Dienstleistungen",
        measureUnit: "Maßeinheit",
        price: "Preis",
        cost: "Kosten",
        tradeMargin: "Handelsspanne (%)",
        newService: "Neuer Service",
        addServiceArticles: "Serviceartikel hinzufügen",

        // articles
        brand: "Marke",
        diameter: "Durchmesser",
        newArticle: "Neuer Artikel",
        coefficient: "Koeffizient",
        updateFromFile: "Update aus Datei",
        updateFromWebpage: "Aktualisierung von der Webseite",
        productsFile: "Produkt-Datei",
        imagesFile: "Bilder-Datei",
        articleGroup: "Artikelgruppe",

        subcontractors: "Subunternehmer",
        subcontractors_employees: "Mitarbeiter von Subunternehmern",
        company_employees: "Firmenangestellte",
        newSubcontractor: "Neuer Unterauftragnehmer",
        subcontractor: "Subunternehmer",
        subcontractorAddress: "Unterauftragnehmer Adresse",
        parent: "Elternteil",
        totalPrice: "Gesamtpreis",
        totalHours: "Gesamtstundenzahl",
        siteAddress: "Website-Adresse",
        workSite: "Arbeitsplatz",
        sameAsClient: "Gleich wie der Kunde",
        emptyLine: "Leere Zeile",
        value: "Wert",
        content: "Inhalt",
        paymentDue: "Zahlung fällig",
        paymentMode: "Zahlungsart",
        paymentMethod: "Zahlungsart",
        bank: "Bank",
        cash: "Kasse",
        downPayment: "Anzahlung",
        discount: "Rabatt",
        amountNoVat: "Betrag ohne MwSt",
        amountWithVat: "Betrag mit MwSt",
        message: "Nachricht",
        attachment: "Befestigung",
        emailSignature: "Email Signatur",
        subject: "Fach",
        to: "Nach",
        generateInvoice: "Rechnungen generieren",
        active: "Aktiv",
        inactive: "Inaktiv",
        all: "Alle",
        year: "Jahr",
        addYear: "Jahr hinzufügen",
        addNewBudgetEstimate: "Neue Budgetschätzung hinzufügen",
        filter: "Filter",
        total: "Gesamt",
        generate: "Generieren",
        timeElapsed: "Zeit vergeht",
        start: "Anfang",
        middle: "Mitte",
        end: "Ende",
        work: "Arbeit",
        signature: "Unterschrift",
        reminderEmailSignature: "E-Mail-Erinnerung Signatur",
        estimateEmailSignature: "E-Mail-Schätzung Signatur",
        orderTicketEmailSignature: "E-Mail Bestellung Signatur",
        startWork: "Arbeitsbeginn",
        duringWork: "Während der Arbeit",
        endWork: "Arbeit beenden",
        gallery: "Galerie",
        expenses: "Kosten",
        expense: "Kosten",
        upload: "Hochladen",
        send: "Senden",
        new: "Neu",
        reset: "Zurücksetzen",
        reimburse: "Zu erstatten",
        subcontractorOrderTickets: "Subunternehmer / Mitarbeiter bestellen",
        subcontractorOrderTicket: "Subunternehmer / Mitarbeiter bestellen",
        employeesOrderTickets: " Mitarbeiter bestellen",
        single: "Single",
        copy: "Kopieren",
        paste: "Paste",
        accounting: "Buchhaltung",
        existing: "Bestehende",
        none: "Keiner",
        contract: "Vertrag",
        employee: "Mitarbeiter",
        employees: "Mitarbeiter",
        activeWorkers: "Aktive Arbeiter",
        lastLocationToday: "Letzter Standort heute",
        locationAlert: "Standortalarm",
        outOfRange: "Außer Reichweite",
        search: "Suche",
        alerts: "Warnungen",
        alertInfo: "Alert-Informationen",
        reports: "Berichte",
        clocking: "Tippen",
        login: "Einloggen",
        logout: "Ausloggen",
        username: "Benutzer",
        password: "Passwort",
        newPassword: "Neues passwort",
        repeatNewPassword: "Passwort neue wiederholen",
        repeatPassword: "Passwort wiederholen",
        role: "Rolle",
        rememberMe: "Erinnere dich an mich",
        forgotPassword: "Haben Sie Ihr Passwort vergessen?",
        passwordsDontMatch: "Die Passwörter stimmen nicht überein.",
        usernameExists: "Dieser Benutzername existiert bereits.",

        //errors
        compulsoryUserPassword:
          "Der Benutzername und das Passwort sind erforderlich.",
        wrongUserOrPassword: "Der Benutzername oder das Passwort ist falsch.",

        // client errors
        clientExists: "Dieser Klient existiert bereits.",
        clientDoesNotExist: "Dieser Klient existiert nicht.",

        // category errors
        categoryExists: "Diese Kategorie existiert bereits.",
        categoryStillUsed:
          "Die Kategorie wird von Dienstleistungen genutzt. Zuerst Kinderdienste löschen.",
        deleteCategory: "Kategorie löschen und ihre Dienste?",

        // services
        ordersWithServiceExist:
          "Projekt, die diesen Dienst enthalten, sind vorhanden. Zuerst löschen Sie abhängige Projekt.",
        serviceDoesNotExists: "Service ist nicht vorhanden.",
        summaryEstimates: "Zusammenfassung der Schätzungen",
        // estimates
        estimateWithSameNumberExists:
          "Eine Schätzung mit der gleichen Nummer existiert bereits.",
        estimateDoesNotExists: "Schätzung existiert nicht.",

        invoiceWithSameNumberExists:
          "Eine Rechnung mit der gleichen Nummer existiert bereits.",
        sameNumberExists: "Die Nummer existiert schon.",

        // general errors
        notFound: "Nicht gefunden.",
        error: "Fehler",
        selectError: "Bitte wählen Sie einen Artikel aus der Liste.",
        saveBaseService: "Grundservice speichern und ersetzen?",
        noInitialServiceFound: "Kein Anfangsdienst gefunden.",
        move: "Bewegung",
        moving: "Ziehen um",
        summary: "Zusammenfassung",
        locate: "Lokalisieren",
        useLocation: "Standort verwenden",
        blocked: "Gesperrt",
        unblocked: "Unblockiert",
        hidePrices: "Preise ausblenden",
        totalToInvoice: "Summe der Rechnung",
        amountLeftToInvoice: "Betrag zur Rechnungsstellung",
        totalAuthorizedToInvoice: "Rechnungsberechtigt",
        alreadyInvoiced: "Bereits in Rechnung gestellt",
        creditCard: "Lastschrift/Kreditkarte",
        dragFiles: "Ziehe Dateien hier",
        open: "Öffnen",
        closed: "Abgeschlossen",
        liability: "Haftung",
        minValue: "Mindestwert",
        maxValue: "Maximalwert",
        docType: "Art des Dokuments",
        paymentTerm: "Zahlungsbedingung",
        notifications: "Benachrichtigungen",
        notification: "Benachrichtigung",
        supplier: "Lieferant",
        equivalent: "Gleichwertig",
        alreadyPaid: "Bereits bezahlt",
        costs: "Kosten",
        presenceOfContractor:
          "In Anwesenheit des Unternehmers (oder seines Vertreters)",
        presenceOfDeveloper: "In Anwesenheit des Baumeisters",
        absenceOfContractor:
          "In Abwesenheit des Unternehmers, der ordnungsgemäß per Einschreiben mit Rückschein geladen wurde",
        contractor: "Auftragnehmer",
        developer: "Bauherr",
        assistant: "Assistent",
        warranty: "Garantie",
        actualCosts: "Tatsächliche Kosten",
        estimatedCosts: "Geschätzte Kosten",
        omniumWarranty:
          "Das Omnium - Es deckt alle: Transport Stundenlohn und Ersatzteile zu ersetzen Garantie - Nur der defekte Teil wird kostenlos ersetzt. Stündliche Löhne und Transport müssen bezahlt werden",
        regularWarranty:
          "Warranty - Only the defective part will be replaced free of charge. Hourly wages and transportation must be paid",
        digressiveWarranty:
          "Die Digressive Garantie - Als die regelmäßige Gewährleistung, außer dass nach einer Reihe von Jahren müssen Sie einen bestimmten Prozentsatz des defekten Teils zu zahlen.",
        unauthorized: "Der Zugang ist nicht erlaubt",
        addText: "Text hinzufügen",
        groupName: "Gruppenname",
        duplicate: "Duplikat",
        optional: "Optional",
        invoicing: "Fakturierung",
        costManagement: "Kostenmanagement",
        representativeName: "Repräsentativer Name",
        generalInfo: "Allgemeine Information",
        bankInfo: "Bank Informationen",
        admin: "Administrator",
        timekeeping: "Zeitmessung",
        map: "Karte",
        clientType: "Client-Typ",
        tenant: "Mieter",
        usufructuary: "Nutznießer",
        manager: "Manager",
        packages: "Pakete",
        package: "Paket",
        docNo: "Dokumentnummer",
        companyName: "Name der Firma",
        companyAddress: "Firmenanschrift",
        paymentDate: "Zahlungsdatum",
        clientNo: "Kundencode",
        clientVatNo: "Kundennummer",
        up: "Oben",
        upgradeForMoreClients: "Ändern Sie den Abonnementplan für mehr Kunden.",
        reconnect:
          "Bei der Wiederherstellung der Daten ist ein Fehler aufgetreten. Bitte melden Sie sich erneut an.",
      },
    };

    this.active = this.lang.en;
    this.locale = LocaleEnum.GB;
  }

  get(key) {
    if (!key) return "";
    if (this.active[key]) {
      return this.active[key];
    }
    for (let objKey in this.active) {
      if (objKey && objKey.toLowerCase() === key.toLowerCase()) {
        return this.active[objKey];
      }
    }
  }

  getActive() {
    return this.locale;
  }

  set(locale) {
    this.locale = locale;
    switch (locale) {
      case LocaleEnum.GB:
        this.active = this.lang.en;
        break;
      case LocaleEnum.FR:
        this.active = this.lang.fr;
        break;
      case LocaleEnum.NL:
        this.active = this.lang.nl;
        break;
      case LocaleEnum.DE:
        this.active = this.lang.de;
        break;
      case LocaleEnum.RO:
        this.active = this.lang.ro;
        break;
      default:
        this.active = this.lang.fr;
        break;
    }
  }

  static getInstance() {
    return Lang.instance ? Lang.instance : (Lang.instance = new Lang());
  }
}

export var LocaleEnum = {
  GB: "gb",
  FR: "fr",
  NL: "nl",
  DE: "de",
  RO: "ro",
};

export const Languages = [
  {
    name: "Français",
    shortName: "fr",
  },
  {
    name: "Nederlandse",
    shortName: "nl",
  },
  {
    name: "English",
    shortName: "gb",
  },
  {
    name: "Deutsche",
    shortName: "de",
  },
  {
    name: "Română",
    shortName: "ro",
  },
];
